@import "src/themes/mixins";
@import "src/themes/variables.module";

.radio-button-group {
  display: flex;
  flex-direction: column;
  color: map-get($colors, 'greys', 'middle');

  .label {
    @include input-label();
  }

  .radio-buttons {
    display: flex;

    > * {
      margin: 0 8px;
    }

    .container {
      display: block;
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      /* Create a custom checkbox container */
      .checkmark-outer-border-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        height: 21px;
        width: 22px;
        clip-path: polygon(80% 5%, 100% 50%, 80% 95%, 20% 95%, 0% 50%, 20% 5%);
        background-color: map-get($colors, 'greys', 'dark');

        .checkmark-inner-border-wrapper {
          position: absolute;
          top: 1px;
          left: 1px;
          right: 1px;
          bottom: 1px;
          background-color: map-get($colors, 'greys', 'light');
          clip-path: polygon(80% 5%, 100% 50%, 80% 95%, 20% 95%, 0% 50%, 20% 5%);

          /* Create a custom checkbox */
          .checkmark {
            position: absolute;
            top: 2px;
            left: 3px;
            right: 3px;
            bottom: 2px;
            background-color: map-get($colors, 'greys', 'light');
            clip-path: polygon(80% 5%, 100% 50%, 80% 95%, 20% 95%, 0% 50%, 20% 5%);
            transition: background-color map-get($transitions, 'medium');
          }

          /* Hide the browser's default checkbox */
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            /* When the checkbox is checked, add a blue background */
            &:checked ~ .checkmark {
              background-color: map-get($colors, 'primary', 'middleDark');
            }

            /* On mouse-over, add a grey background color */
            &:hover:enabled ~ .checkmark {
              background-color: map-get($colors, 'primary', 'middleDark');
            }
          }
        }
      }
    }
  }

  .radio-buttons.vertical {
    flex-direction: column;
  }
}

.radio-button-group.disabled {
  @include disabled-input();
}