@import 'src/themes/variables.module';
@import 'src/themes/mixins';

.stage {
  width: 100%;
  position: relative;
  margin-bottom: calc(calc(calc(91px + calc(45px * 2)) * -1) + 80px);
  z-index: 1;

  &.Large {
    --stageMinHeight: 775px;
  }

  &.Normal {
    --stageMinHeight: 610px;
  }

  &.Small {
    --stageMinHeight: 450px;
  }

  > .Left {
    align-items: flex-start;
  }

  > .Right {
    align-items: flex-end;
  }

  > .Center {
    align-items: center;
  }

  > div:first-of-type {
    width: 100%;
    min-height: var(--stageMinHeight);
    top: 0;
    left: 0;
  }

  > div > div:first-of-type {
    @include desktop-and-smaller {
      min-height: var(--stageMinHeight);
    }

    @include mobile-only {
      padding-left: #{map-get($spacings, 'smallLarge')};
      padding-right: #{map-get($spacings, 'smallLarge')};
    }

    max-width: 1420px;
    margin-left: auto;
    margin-right: auto;
    min-height: var(--stageMinHeight);

    width: calc(100% - 32px);
    display: flex;
    justify-content: center;
    flex-direction: column;

    > svg {
      max-width: 100%;
    }

    > * {
      > * {
        margin: 10px 0;
      }
    }

    &.vert-center {
      padding-top: calc(calc(91px + calc(25px * 2)) + 50px);
      padding-bottom: calc(91px + calc(25px * 2));
      justify-content: center;
    }

    &.vert-top {
      justify-content: flex-start;
      padding-bottom: calc(91px + calc(25px * 2));
      padding-top: calc(91px + calc(25px * 2));
    }

    &.vert-bottom {
      justify-content: flex-end;
      padding-top: calc(calc(91px + calc(25px * 2)) + 50px);
      padding-bottom: 91px;
    }

    &.inverted {
      > p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      span,
      p {
        color: map-get($colors, 'greys', 'light');
      }
    }

    &.LEFT {
      > p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      span {
        text-align: left;
      }

      .title {
        text-align: left;
      }

      align-items: flex-start;
    }

    &.CENTER {
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      span {
        text-align: center;
      }

      .title {
        text-align: center;
      }

      align-items: center;
    }

    &.RIGHT {
      > p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      span {
        text-align: right;
      }

      .title {
        text-align: right;
      }

      align-items: flex-end;
    }
  }

  > div > div:last-of-type {
    position: relative;
  }

  .decButtonContainer {
    text-align: center;
    position: absolute;
    width: 150px;
    bottom: -80px;
    margin-left: auto;
    margin-right: auto;
    right: 0;
    left: 0;
  }

  .decButtonCustomContainer {
    position: absolute;
    bottom: -72px;
    left: calc(50% - 30px);
  }

  .title {
    padding-top: 16px;
  }

  .description {
    padding-top: 50px;
    text-align: center;
  }

  .buttons {
    padding-top: 50px;
  }

  .default-background {
    background-image: url("~/src/assets/default_header.jpg");
  }

  .stage-background {
    background-size: cover;
    background-position: top center;
  }
}

.inverted {
  color: map-get($colors, "greys", "light");

  .text, .text * {
    color: map-get($colors, "greys", "light");
  }
}