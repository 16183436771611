@import "src/themes/variables.module";

.flip-icon {

  .outside {
    fill: map-get($colors, 'greys', 'dark');
  }

  .inside {
    fill: map-get($colors, 'greys', 'light');
  }

  &.inverted {
    .outside {
      fill: map-get($colors, 'greys', 'light');
    }

    .inside {
      fill: map-get($colors, 'greys', 'dark');
    }
  }

  &:hover {
    .inside {
      fill: map-get($colors, 'primary', 'middleDark');
    }
  }
}