@import "src/themes/mixins";

.comments-section p {
  font-size: 12px!important;
  line-height: 20px!important;
}

.fullwidth {
  max-width: unset;
  width: 100%;
}

.fullwidth_margin {
  width: calc(100% - 32px);
  margin-left: auto;
  margin-right: auto;
}

.normal {
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
}

.controls, .login-message {
  max-width: 32rem;
}

.login-message ul {
  list-style-position: inside;
  display: inline;
  p {
    display: inline;
  }
}

.text-area {
  div[data-testid=error-message] {
    height: initial;
  }
}

.button-wrapper {
  margin-top: 12px;
}

.inverted ul {
  color: map-get($colors, 'greys', 'light');
}