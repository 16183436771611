@import "src/themes/mixins";
@import "src/themes/variables.module";

.default-header-mobile-login-form {
  width: 260px;
  position: fixed;
  z-index: 99999;
  display: inline-block;
  right: calc(50% - 700px);
  top: 55px;

  @include mobile-and-tablet {
    right: 35px !important;
    top: 45px !important;
  }
  @include desktop-small-only {
    right: 31px !important;
  }

  &.showOnlyLeastError {
    :global(.error-message) {
      display: none;
    }
  }
}

.register-link-container {
  width: 100%;
  margin: 0 map-get($spacings, 'medium');

  .registerLink {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > svg {
      margin-left: 5px;
    }
    > svg,
    > svg > path {
      fill: map-get($colors, "greys", "dark");
      color: map-get($colors, "greys", "dark");
    }

    &:hover {
      > svg,
      > svg > path {
        fill: map-get($colors, "primary", "middleDark");
        color: map-get($colors, "primary", "middleDark");
      }
    }
  }
}
