@import "src/themes/mixins";

.text-section {
  .headline {
  }

  .text {
    padding: 15px 0;
  }
}

@include mobile-only {
  .text-section {

    .text {
      padding-top: 16px;
    }
  }
}