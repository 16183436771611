@import "src/themes/variables.module";

.footer {
  position: relative;
  padding: 12px 16px;

  .blockchain-icon {
    position: absolute;
    top: 20px;
    right: 16px;

    > * {
      scale: 1.5;
    }
  }

  .separator {
    border-top: 1px solid map-get($colors, 'greys', 'dark');
  }

  .price-wrapper {
    display: flex;
    padding-top: 16px;
    min-height: 41px;

    .price-prefix {
      font-size: 14px;
      padding-right: 8px;
    }

    .price {
      font-family: hero-new;
      line-height: 23px;
      padding-right: 8px;
    }

    .secondary-price {
      font-size: 14px;
    }

    .buy-button {
      padding-left: 8px;
    }
  }

  .swap-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
  }

  &.inverted {
    .price {
      color: map-get($colors, 'greys', 'light');
    }

    .price-prefix {
      color: map-get($colors, 'greys', 'light');
    }

    .secondary-price {
      color: map-get($colors, 'greys', 'light');
    }

    .separator {
      border-top: 1px solid map-get($colors, 'greys', 'light');
    }
  }

  .countdown {
    color: map-get($colors, primary, middleDark);
    font-family: cy;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 20px;
    letter-spacing: 0.27px;
  }
  
  .clock {
    margin-right: 5px;
  }
}