@import "src/themes/variables.module";

.header-mobile-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 12px 12px 20px;

  > * {
    margin: 5px auto;
  }
  > h5 {
    width: 100%;
    margin: 0 map-get($spacings, 'medium');
    padding: 5px 0;
  }
  > div {
    display: flex;
    flex-direction: column;
    padding: 5px;

    > * {
      margin: 10px 0;
    }
  }

  .global-error-message {
    margin-top: -10px;
    width: 100%;
    height: 100%;
    max-width: 206px;
  }

  .forgot-password-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .forgot-password {
    display: flex;
    justify-content: flex-start;
    padding-left: 8px;
    margin-top: -2px;
    align-items: center;

    span {
      font-size: 16px;
    }

    > svg {
      margin-left: 5px;
    }
    > svg,
    > svg > path {
      fill: map-get($colors, "greys", "dark");
      color: map-get($colors, "greys", "dark");
    }

    &:hover {
      > svg,
      > svg > path {
        fill: map-get($colors, "primary", "middleDark");
        color: map-get($colors, "primary", "middleDark");
      }
    }
  }
}
.submit-button-container {
  justify-content: flex-start;
  margin: 0 map-get($spacings, 'medium');
  padding-right: 20%;
}