@import "src/themes/variables.module";

.card {
  background-color: map-get($colors, "greys", "light");
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px -14px rgba(0,0,0,0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: initial;
  height: 100%;

  &:hover {
    .card-image {
      filter: contrast(100%);
    }
  }

  img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
  }
}

.card-content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem;
}

.card-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  filter: contrast(70%);
  overflow: hidden;
  position: relative;
  transition: filter 0.5s cubic-bezier(.43,.41,.22,.91);
}

.card-title {
  font-size: 1.25rem;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.card-text {
  flex: 1 1 auto;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.card-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-bottom: 1rem;

  .category {
    text-align: center;
    border: none;
    -webkit-clip-path: polygon(0.8660008083rem 0,calc(100% - 0.8660008083rem) 0,100% 50%,calc(100% - 0.8660008083rem) 100%,0.8660008083rem 100%,0 50%);
    clip-path: polygon(0.8660008083rem 0,calc(100% - 0.8660008083rem) 0,100% 50%,calc(100% - 0.8660008083rem) 100%,0.8660008083rem 100%,0 50%);
    padding: 0.4rem 0.7rem;
    font-size: 14px;
    background-color: map-get($colors, "greys", "dark");
    color: map-get($colors, "greys", "light");

    &:hover {
      color: map-get($colors, 'primary', 'middleDark');
    }
  }
}

.card-categories.spacing {
  margin-bottom: 0;
}

.card-author {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  justify-content: center;
  font-size: 15px;
  font-weight: lighter;

  .author {
    display: flex;
    align-items: center;
    flex-grow: 1;

    .author-image {
      width: 25px;
    }
  }
}

.card-author.detail {
  .author-name{
    font-size: 18px;
    font-weight: bold;
  }

  .author-image {
    width: 80px;
  }
}

.news-related-headline {
  margin-top: 3rem;
}

.news-related-news {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  list-style: none;
  margin: 2rem 0 0 0;
  padding: 0;

  .news-item {
    flex: 1 0 100%;

    @media(min-width: 48rem) {
      flex: 1 0 40%;
    }

    @media(min-width: 72rem) {
      flex: 1 0 30%;
    }
  }
}

.thumbnail-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding-bottom: 30px!important;

  .thumbnail {
    flex: 1;

    img {
      object-fit: cover;
      max-width: 100%;
      height: auto;
      vertical-align: middle;
      border-radius: 5px;
    }
  }
}

.spacing {
  padding: 15px 0;
}

.wrapped {
  max-width: 1000px;
  margin: 0 auto;
}

.academy_template {
  height: 100%;
}

.link {
  text-decoration: none;
}

.inverted {
  .card {
    background-color: map-get($colors, "greys", "darkAccent");
  }

  .card-categories {
    .category {
      background-color: map-get($colors, "greys", "darkLight");
    }
  }
}