@import 'src/themes/variables.module';
@import 'src/themes/mixins';

$animation-duration: .3s;

.header {
    display: flex;
    justify-content: center;
}

.header-container.lite {
    display: flex;
}

@media only screen and (min-width: 1430px) {
    .header-container:not(.lite) {
        max-width: 1420px;
        margin: 0 auto;
    }
}

.header-background-container {
    width: 100%;
    height: calc(91px + calc(45px * 2));

    > div:nth-of-type(2) {
        width: 100%;
        padding: 0 20px;
        height: calc(91px + calc(45px * 2));
        @media only screen and (min-width: 1430px) {
            max-width: 1420px;
            margin: 0 auto;
        }
    }
}
    .sticky{
        position:fixed;
        transition: background-color $animation-duration ease-out, opacity $animation-duration ease-out, visibility $animation-duration ease-out;
        opacity: 1;
        visibility: visible;
        background-color: rgba(0, 0, 0, 1)!important;
        display: flex;
        min-width: 100%;
        top: 0;
        left:0;
        z-index: 999;
    }

    .header-container {
        display: flex;
        width: 100%;
        height: calc(91px + calc(45px * 2));
        transition: opacity $animation-duration ease-out, visibility $animation-duration ease-out;
        opacity: 1;
        visibility: visible;
        align-items: center;
        justify-content: space-between;
        
        padding: 45px 0;

        z-index: #{map-get($zIndices, 'basic-elements')};

        @include mobile-and-tablet{
            height: 80px;
            margin: 0  map-get($spacings, 'smallLarge');
        }
        @include desktop-and-desktop-small{
            height: 120px;
        }
        @include desktop-and-smaller{
            margin: 0  map-get($spacings, 'smallLarge');
        }

        &.inverted {
            > .logo {
                position: relative;
                z-index: #{map-get($zIndices, 'pop-up-overlays')};
                fill: #{map-get($colors, 'greys', 'light')};
            }
        }

        > .nav-container {
            position: relative;
            z-index: #{map-get($zIndices, 'pop-up-overlays')};
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 100%;
            right: - map-get($spacings, 'smallLarge');
            > * {
                margin-left: map-get($spacings, 'smallLarge');
                width: unset !important;
            }
        }

        > .buttons {
            position: relative;
            z-index: #{map-get($zIndices, 'pop-up-overlays')};
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;

            > * {

                margin: 0 auto;

            }

            > div {
                margin: 10px 0;
            }

            :hover svg {
                > path{
                    fill:#{map-get($colors, "primary", "middleDark")};
                }
                > g > path {
                    fill:#{map-get($colors, "primary", "middleDark")};
                    stroke:#{map-get($colors, "primary", "middleDark")};
                }

            }
            > button {
                width: 100%;
                display: flex;
                left:0;
                @include desktop-and-desktop-small {
                    min-height: 50px;
                    margin-bottom: 13px;
                    transform: scale(0.8);
                    transform-origin: right;
                    
                    > span {
                        transform: scale(1.2);
                        margin-left: map-get($spacings, 'small');
                    }
                    > div:nth-of-type(1) {
                        min-height: 40px;
                        bottom: 8px;
                        left: 21px;
                        padding: 19.8px;
                        > svg {
                            transform: scale(1.2);
                        }
                    }
                    > span:nth-of-type(1) {
                        padding-left: map-get($spacings, 'medium');
                        margin-bottom: 1px;
                    }
                }
                @include mobile-and-tablet {
                    margin-left: map-get($spacings, 'smallLarge');
                }
            }
        }

        .checkout-button-wrapper {
            > button {
                @include desktop-and-desktop-small{
                    -webkit-transform: scale(0.8);
                    -moz-transform: scale(0.8);
                    transform: scale(0.8);
                    min-height: 50px;
                    margin-top: -13px;
                    margin-right: -32px;
                    margin-left: 32px;
                }
                @include mobile-and-tablet {
                    margin-left: map-get($spacings, 'smallLarge');
                }
                > svg {
                    transform: scale(1.2);
                }
            }
        }
    }

.logo {
    position: relative;
    z-index: #{map-get($zIndices, 'pop-up-overlays')};
    fill: #{map-get($colors, 'greys', 'light')};
    flex: 0;
    height: 100%;
    display: flex;
    align-items: center;

    @include desktop-and-desktop-small {
        height: 70px;
        width: 80px;
        cursor: pointer;
    }
    @include mobile-and-tablet{
        height: 60px;
        width: 70px;
    }
    padding: 0;
}

.transparent {
    background-color: rgba(0,0,0,0)!important;
}

.scrolled {
    background-color: rgba(0, 0, 0, 0)!important;
    opacity: 0;
    visibility: hidden;
    .header-container {
        opacity: 0;
        visibility: hidden;
    }
}
