@import 'src/themes/variables.module';
@import "src/themes/mixins";

.input-field-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @include input-label();

  .input-field {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;

    input {
      @include basic-input();
    }

    .valid-icon {
      position: absolute;
      top: 16px;
      right: 16px;
      color: map-get($colors, 'signalColors', 'success');
    }

    input.valid {
      border: 1px solid map-get($colors, 'signalColors', 'success');
    }

    input.error {
      border: 1px solid map-get($colors, 'signalColors', 'error');
    }
  }

  .error {
    color: map-get($colors, 'signalColors', 'error');
  }
}

.input-field-wrapper.disabled {
  @include disabled-input();
}

.input-field-wrapper.inverted {
  @include inverted-input();
}
