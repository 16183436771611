.iframe-box {
  .iframe-container {
    overflow: auto;
    padding-top: 56.25%;
    position: relative;
  }

  iframe {
    border: 0;
    height: 100%;
    left: 50%;
    position: absolute;
    top: 0;
    width: 100%;
    transform: translateX(-50%);
    align-self: center;
    overflow: auto;
  }
}

.fullwidth {
  max-width: unset;
  width: 100%;
}

.fullwidth_margin {
  width: calc(100% - 32px);
  margin-left: auto;
  margin-right: auto;
}

.normal {
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
}