@use "sass:math";
@import "src/themes/variables.module";
@import "src/themes/mixins";

$border-width: 1px;
$sqrt3: 1.7321; // Square-root of 3
$hex-height: 3rem;
$hex-min-width: math.div($hex-height * $sqrt3, 2);
$hex-distance: (math.div($hex-height, 2 * $sqrt3)); // d = h / (2*tan(60°)); with h = the height of the button

.read-more,
.link-small {
    display: flex;
    align-items: center;
    color: map-get($colors, "primary", "dark");
    text-decoration: none;
    font-size: 0.75em;
    line-height: 2em;
    background: unset;
    border: unset;
    text-align: unset;
    cursor: pointer;

    > svg {
        margin-left: 5px;
        vertical-align: unset;
        height: unset;
        font-size: inherit;
        font-size: 1.2em;
    }

    &:hover {
        color: map-get($colors, "primary", "middleDark");

        > span {
            color: map-get($colors, "primary", "middleDark");
        }

      .link-icon {
          color: map-get($colors, "primary", "middleDark");
      }
    }

    &.disabled {
        color: map-get($colors, "greys", "middleLight");
        pointer-events: none;

        > span {
            color: map-get($colors, "greys", "middleLight");
        }
    }
}

.button-secondary-outlined.button-secondary-outlined {
    all: unset;
    background: unset;
    border: none;
    outline: none;
    &:disabled {
        cursor: default !important;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    appearance: none;
    position: relative;
    min-height: 54px;
    min-width: $hex-min-width;
    height: $hex-height;
    margin: auto;
    padding: 0 #{$hex-distance * 1.5};
    text-align: center;
    font-size: 1rem;
    line-height: 1rem;
    vertical-align: middle;
    color: #{map-get($colors, "greys", "dark")};
    cursor: pointer;

    > span {
        all: unset;
        @include button;
        color: #{map-get($colors, "greys", "dark")};
    }

    clip-path: polygon(
        #{$hex-distance} 0,
        calc(100% - #{$hex-distance}) 0,
        100% 50%,
        calc(100% - #{$hex-distance}) 100%,
        #{$hex-distance} 100%,
        0 50%
    );

    > div:global(.withText.right) {
        margin-left: 15px;

        bottom: 8px;
        left: 21px;
    }

    > div:global(.withText.left) {
        margin-right: 15px;

        bottom: 8px;
        right: 21px;
    }

    > div:global(.withText) {
        display: flex;
        justify-content: center;
        align-items: center;
        appearance: none;
        position: relative;
        min-height: 54px;
        min-width: $hex-min-width;
        height: $hex-height;
        margin: auto;
        padding: 0.5rem #{$hex-distance * 1.5};
        text-align: center;
        font-size: 1rem;
        line-height: 1rem;
        vertical-align: middle;
        color: #{map-get($colors, "greys", "dark")};
        cursor: pointer;
        border: none;

        clip-path: polygon(
            #{$hex-distance} 0,
            calc(100% - #{$hex-distance}) 0,
            100% 50%,
            calc(100% - #{$hex-distance}) 100%,
            #{$hex-distance} 100%,
            0 50%
        );

        height: calc(100% + calc(0.5rem * 2));

        position: relative;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: #{map-get($colors, "greys", "dark")};
            clip-path: polygon(
                0 50%,
                #{$border-width} 50%,
                calc(#{$hex-distance} + #{math.div($border-width, 2)}) #{$border-width},
                calc(100% - #{$hex-distance} - #{math.div($border-width, 2)}) #{$border-width},
                calc(100% - #{$border-width}) 50%,
                calc(100% - #{$hex-distance} - #{math.div($border-width, 2)}) calc(100% - #{$border-width}),
                calc(#{$hex-distance} + #{math.div($border-width, 2)}) calc(100% - #{$border-width}),
                #{$border-width} 50%,
                0 50%,
                0 100%,
                100% 100%,
                100% 50%,
                100% 0,
                0 0
            );
        }
    }

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #{map-get($colors, "greys", "dark")};
        clip-path: polygon(
            0 50%,
            #{$border-width} 50%,
            calc(#{$hex-distance} + #{math.div($border-width, 2)}) #{$border-width},
            calc(100% - #{$hex-distance} - #{math.div($border-width, 2)}) #{$border-width},
            calc(100% - #{$border-width}) 50%,
            calc(100% - #{$hex-distance} - #{math.div($border-width, 2)}) calc(100% - #{$border-width}),
            calc(#{$hex-distance} + #{math.div($border-width, 2)}) calc(100% - #{$border-width}),
            #{$border-width} 50%,
            0 50%,
            0 100%,
            100% 100%,
            100% 50%,
            100% 0,
            0 0
        );
    }

    &:hover:not(:disabled) {
        color: #{map-get($colors, "primary", "middleDark")};

        > div:global(.withText) {
            > svg {
                color: #{map-get($colors, "primary", "middleDark")};
            }
            &::before {
                background-color: #{map-get($colors, "primary", "middleDark")};
            }
        }

        > span {
            color: #{map-get($colors, "primary", "middleDark")};
        }
        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: #{map-get($colors, "primary", "middleDark")};
        }
    }

    &:disabled {
        color: #{map-get($colors, "greys", "middleDark")};

        > span {
            color: #{map-get($colors, "greys", "middleDark")};
        }
        &::before {
            background-color: #{map-get($colors, "greys", "middleDark")};
        }

        > div:global(.withText) {
            color: #{map-get($colors, "greys", "middleDark")};

            &::before {
                background-color: #{map-get($colors, "greys", "middleDark")};
            }
        }
    }
}

.link-icon {
    color: map-get($colors, "greys", "dark");
    padding-left: 1px;
    width: 0.625em;

    &.inverted {
        color: map-get($colors, "greys", "light");
    }
}
