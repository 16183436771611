@import "src/themes/variables.module";

.audio-player {
  max-width: 740px;
  padding: 24px;
  margin: auto;
}

.artwork {
  border-radius: 20px;
  display: block;
  margin: auto;
  width: 100%;
  max-width: 420px;
  max-height: none!important;
  height: auto!important;
}

.track-info {
  text-align: center;
  z-index: 1;
  position: relative;
}

.title {
  font-weight: 700;
  margin-top: 4px;
}

.description {
  white-space: pre-wrap;
  font-weight: 300;
  margin-top: 4px;
}

.controls {
  display: flex;
}

.progress-timer {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  width:  100%;
  margin: 2px 0;
}