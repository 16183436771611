@import "src/themes/variables.module";
@import "src/themes/mixins";

.submit-input {
  position: relative;
  max-width: 1000px;
  width: 100%;
  display: flex;

  input {
    @include basic-input();
    margin-right: 15px;
  }

  input.error {
    border: 1px solid map-get($colors, 'signalColors', 'error');
  }

  input.inverted {
    background-color: #000000 !important;
    color: white;
  }

  > button {
    position: absolute;
    min-width: 64px;
    min-height: 58px;
    right: 0;
  }

  > button.inverted {
    background-color: white;

    svg,
    path {
      fill: black;
    }
  }
}