@import "src/themes/variables.module";

.topline {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  margin-right: 36px;
  min-height: 20px;
}

.title-wrapper {
  width: fit-content;
  max-width: 100%;
  padding-top: 1%;
  padding-bottom: calc(33% - 100px);
  padding-right: 36px;
}

.title {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  height: 23px;
}

.link-title {
  &:hover {
    color: map-get($colors, 'primary', 'middleDark') !important;
  }
}

.inverted {
  .topline {
    color: map-get($colors, 'greys', 'middleLight');
  }

  .title {
    color: map-get($colors, 'greys', 'light');
  }
}