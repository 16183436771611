.stage-image-button-token {
    transform: scale(2) rotate(90deg);
    position: relative;

    button:first-of-type {
        width: 63px;
        > div {
            > img {
                transform: rotate(-90deg);
                height: 70px;
                width: 70px;
                object-fit: cover;
            }
        }
    }
}

.editButton {
    transform: scale(0.3) rotate(-90deg);
    position: absolute;
    bottom: 16px;
    right: -22px;
    z-index: 4;
}

.token-button {
    cursor: default;
}