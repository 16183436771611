@import "src/themes/mixins";

.menu_abstract-section {
  .headline * {
    color: map-get($colors, "greys", "dark");
  }

  .text {
    padding: 19px 0;
    color: map-get($colors, "greys", "darkLight");
  }

  a {
    text-decoration: none;
    color: map-get($colors, "greys", "darkLight");
  }

  a:hover {
    color: map-get($colors, 'primary', 'middleDark');
  }

  ul {
    list-style-position: inside;
    li {
      color: map-get($colors, "greys", "darkLight");
    }
  }
}

.inverted {
  color: map-get($colors, "greys", "light");

  .text {
    color: map-get($colors, "greys", "light");
  }

  .headline * {
    color: map-get($colors, "greys", "light");
  }

  a {
    color: map-get($colors, "greys", "light");
  }

  ul {
    li {
      color: map-get($colors, "greys", "light");
    }
  }
}

.fullwidth {
  max-width: unset;
  width: 100%;
}

.fullwidth_margin {
  width: calc(100% - 32px);
  margin-left: auto;
  margin-right: auto;
}

.normal {
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
}

@include mobile-only {
  .text {
    padding: 16px 0;
  }
}