@import "src/themes/variables.module";

.nav-button {

  button {
    border: none;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    display: flex;

    .text {
      line-height: 21px;
      color: map-get($colors, "primary", "middleDark");
    }

    .icon {
      color: map-get($colors, "primary", "middleDark");
      margin-top: 4px;
      margin-left: 4px;
    }
  }

  a {
    text-decoration: none;
  }
}