@import "src/themes/mixins";
@import "src/themes/variables.module";

.text-area-field-wrapper {
  @include input-label();
  display: flex;
  flex-direction: column;

  .error {
    color: map-get($colors, 'signalColors', 'error');
  }

  .text-area {
    flex: 1;
    display: flex;
    flex-direction: column;

    textarea {
      @include basic-input();
      flex: 1;
      resize: none;
    }

    textarea.error {
      border: 1px solid map-get($colors, 'signalColors', 'error');
    }
  }

  .char-counter {
    font-size: 14px;
    width: 100%;
    text-align: right;
    color: map-get($colors, 'greys', 'middle');
  }
}

.text-area-field-wrapper.disabled {
  @include disabled-input();
}

.text-area-field-wrapper.inverted {
  @include inverted-input();
}
