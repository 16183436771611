@import "src/themes/variables.module";

.media-component {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;

  &.dimensional:hover {
    z-index: 2;
  }
}

.caption {
  font-family: hero-new, sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: map-get($colors, 'greys', 'dark');
  padding: 8px 0;
}

.inverted {
  .caption {
    color: map-get($colors, 'greys', 'light');
  }
}

.clickable {
  cursor: pointer;
}

.squared-video {
  padding-top: 100%;
  height: 0;
  position: relative;
  overflow: hidden;

  video {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.hexagon {
  height: 100px;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;

  img {
    clip-path: polygon(7% 25%, 50% 0, 93% 25%, 93% 75%, 50% 100%, 7% 75%);
  }
}

.custom-play-button {
  position: absolute;
  bottom: 5px;
  right: 5px;
  cursor: pointer;
  /*Safari incorrect stacking images workaround fix*/
  -webkit-transform:translate3d(0, 0, 5px);

  svg {
    &:hover {
      path {
        fill: map-get($colors, 'primary', 'lightAccent');
      }
      circle {
        opacity: 1.0;
      }
    }
    height: 100%;
  }
}

.dimensional-card {
  perspective: 400px;
  transition: all 250ms ease-out;

  &:hover {
    z-index: 10;
  }

  img {
    transition: all 250ms ease-out;
  }
}

.yt-container {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 56.25%;
}

.yt-container iframe,
.yt-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
}

.yt-wrap {
  width: 100%;
  max-width: 600px;
}

.audio-wrap {
  text-align: center;
}

.animate__animated {
  position: relative;
  transition: all .2s;
  transition-property: box-shadow, transform, background, opacity;

  .caption {
    transition: padding .2s;
  }

  &:hover.animate__zoomIn {
    transform: scale(1.1);
    -webkit-box-shadow: 4px 4px 8px -1px rgba(0,0,0,0.44);
    box-shadow: 4px 4px 8px -1px rgba(0,0,0,0.44);

    &:hover {
      z-index: 10;
    }

    &:hover .caption {
      padding: 8px;
      background: map-get($colors, "greys", "light");
    }
  }

  &:hover.animate__transparent {
    opacity: 0.5;
  }
}

.inverted {
  .animate__animated  {
    &:hover.animate__zoomIn .caption {
      background-color: map-get($colors, "greys", "darkAccent");
    }
  }
}