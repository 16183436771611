@import "src/themes/variables.module";

.product-card-details-wrapper {
  display: flex;
  flex-direction: column;

  .link {
    text-decoration: none !important;
  }

  .headline-url {
    text-decoration: none;
  }

  .topline {
    color: map-get($colors, "greys", "middleDark");
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-bottom: 6px;
    @media (max-width: #{map-get($breakpoints,mobile)}) {
      padding-bottom: 12px;
    }
  }

  .markdown-text {
    white-space: pre-wrap;
  }

  .topline-text-image-teaser {
    padding-bottom: 12px;
  }

  .headline-wrapper {
    .headline {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-word;
      overflow: hidden;
    }

    .headline-clickable {
      cursor: pointer;
      &:hover,
      &:active {
        color: #{map-get($colors, "primary", "middleDark")};
      }
    }
  }

  .sub-headline {
    padding-top: 6px;
  }

  .description-wrapper {
    padding-top: 15px;
    padding-bottom: 15px;

    .description {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .description-basic-card-horizontal-small {
      -webkit-line-clamp: 3;
    }

    .description-magazine-content-teaser {
      -webkit-line-clamp: 6;
    }

    .description-podcast-teaser {
      -webkit-line-clamp: 3;
    }

    .description-text-image-teaser {
      padding-top: 19px;

      @media (max-width: #{map-get($breakpoints,mobile)}) {
        padding-top: 10px;
      }
    }
  }

  .description-wrapper-text-image-teaser {
    @media (max-width: #{map-get($breakpoints,mobile)}) {
      padding-top: 30px;
    }
  }

  &.purchase-card-normal {
    .headline-wrapper {
      padding-bottom: 10px;
      height: 60px;
    }
  }

  &.basic-card-small {
    .headline-wrapper {
      height: 100px;
    }
  }

  &.creatoken-card {
    .headline-wrapper {
      height: 50px;
    }
  }

  &.basic-card-horizontal-small {
    .headline-wrapper {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.2px;
      height: 48px;
      overflow: hidden;
    }

    .description-wrapper {
      height: 142px;
      overflow: hidden;
    }

    @media (max-width: #{map-get($breakpoints, mobile)}) {
      .description-wrapper {
        height: 102px;

        p {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.16px;
        }
      }
    }
  }

  &.podcast-teaser {
    .headline-wrapper {
      font-size: 20px;
      letter-spacing: 0.2px;
      height: 62px;
      max-width: 700px;
      overflow: hidden;
      span {
        line-height: 30px;
      }
    }

    .description-wrapper {
      padding: 0;
      margin-bottom: 15px;
      margin-top: 15px;
    }
  }

  .paid-price-wrapper {
    border-top: 1px solid map-get($colors, "greys", "middleLight");
    padding: 10px 0;
    display: flex;
    flex-direction: column;

    .paid-price {
      font-family: hero-new;
    }
  }

  .token-id-wrapper {
    border-top: 1px solid map-get($colors, "greys", "middleLight");
    padding: 10px 0;
    display: flex;
    flex-direction: column;

    .token-id {
      font-family: hero-new;
    }
  }
}