@import "src/themes/variables.module";

.accord-box {
    height: 100%;
    max-width: 700px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto!important;
    margin-right: auto!important;

    > :global(.accordion) {
        margin: 20px 0;
    }

    &.inverted {
        > p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        span,
        p {
            color: map-get($colors, 'greys', 'light');
        }

        > :global(.accordion) {
            > :global(.accordion-header),
            > :global(.accordion-content),
            > :global(.content) {
                background-color: #000;
            }

            > :global(.accordion-header) svg {
                color: #fff;
            }
        }
    }

    .accord-item-content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 -10px;
        position: relative;

        > div {
            margin: 0 10px;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        > .accord-item-image {
            flex: 0;
            max-width: 241px;
        }

        @media (max-width: #{map-get($breakpoints, mobile)}) {
            flex-direction: column;

            > .accord-item-image {
                align-self: center;
                width: 100%;
            }
        }
    }

    .accord-description {
        margin-bottom: 25px;
    }
}

.accord-item-description {
    margin-right: 18px;
    font-size: 14px;
    line-height: 22px;
}

.fullwidth {
    max-width: unset;
    width: 100%;
}

.fullwidth_margin {
    width: calc(100% - 32px);
    margin-left: auto;
    margin-right: auto;
}

.normal {
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
}