@import "src/themes/variables.module";

.comment-section {
  display: flex;
  gap: 15px;
  position: relative;
  margin-bottom: 12px;

  &:hover .options {
    display: block;
  }
}

.avatar {
  img {
    width: 38px;
    height: 38px;
    max-width: none!important;
    border-radius: 50%;
  }
}

.controls {
  display: flex;
  gap: 6px;
  margin-top: 3px;
  p {
    font-size: 10px!important;
  }

  .control-delete {
    cursor: pointer;
    svg {
      font-size: 8px;
      vertical-align: middle;
      margin-bottom: 3px!important;
    }
    p {
      color: map-get($colors, "signalColors", "error");
    }
  }
}

.removed {
  padding: 5px;
  border: 1px solid map-get($colors, "signalColors", "error");
  p {
    text-align: center;
  }
}