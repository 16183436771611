@import "src/themes/variables.module";

.header {
  display: flex;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 2;

  :last-child {
    margin-left: auto;
  }

  .left-header {
    position: relative;
    background-color: rgba(0, 0, 0, 0.7);
    color: map-get($colors, 'greys', 'light');

    &:after {
      content: '';
      position: absolute;
      right: -26px;
      top: 0;
      width: 26px;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      clip-path: polygon(100% 0, 0 0, 0 100%);
    }

    .categories {
      padding-top: 8px;
      display: flex;

      > * {
        padding: 0 8px;
      }

      &:first-child {
        padding-left: 8px;
      }
    }
  }

  .unlockables {
    padding-top: 8px;
    padding-left: 32px;
    scale: 1.2;
  }

  .menu {
    cursor: pointer;
    opacity: 0.7;
    fill: map-get($colors, 'greys', 'light');

    &:hover {
      fill: map-get($colors, 'primary', 'middleDark');
    }

    &.active {
      opacity: 1;
      fill: map-get($colors, 'primary', 'middleDark');
    }
  }
}