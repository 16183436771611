@import 'src/themes/variables.module';

.error-message-wrapper {
    height: 1em;

    .error-message {
        font-size: 0.75em;
        color: map-get($colors, 'signalColors', 'error');
        text-align: left;
    }
}
