@import "src/themes/variables.module";

.front {
  display: flex;
  flex-direction: column;
  height: 100%;
  // fix for flickering in Safari
  -webkit-transform: translateZ(0);
  transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  overflow-y: hidden;
  border-top: 1px solid map-get($colors, 'greys', 'dark');
  border-left: 1px solid map-get($colors, 'greys', 'dark');
  border-right: 1px solid map-get($colors, 'greys', 'dark');
  width: 100%;

  &.inverted {
    border-top: 1px solid map-get($colors, 'greys', 'light');
    border-left: 1px solid map-get($colors, 'greys', 'light');
    border-right: 1px solid map-get($colors, 'greys', 'light');
    background-color: map-get($colors, 'greys', 'dark');
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: hidden;

    .overlay {
      flex: 1;
      overflow-y: hidden;
      z-index: 2;
    }

    .media {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
}

// fix for disappearing border-bottom in Chrome
.teaser-border {
  height: 1.5px;
  background-color: map-get($colors, 'greys', 'dark');

  &.inverted {
    background-color: map-get($colors, 'greys', 'light');
  }
}