@import "src/themes/mixins";

.html {
  padding: 0;
  margin: 0;
  color: #000;
}

.inverted * {
  color: #fff;
}

.fullwidth {
  max-width: unset;
  width: 100%;
}

.fullwidth_margin {
  width: calc(100% - 32px);
  margin-left: auto;
  margin-right: auto;
}

.normal {
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
}