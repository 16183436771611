@import "src/themes/variables.module";

.header-nav-item {
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: baseline;
    &.inverted {
        > span {
            color: #{map-get($colors, "greys", "light")};
        }
    }

    box-sizing: border-box;

    text-decoration: none;

    > span {
        display: inline-flex;
        margin: unset !important;
        position: relative;

        &::after {
            content: attr(data-text);
            content: attr(data-text) / "";
            height: 0;
            visibility: hidden;
            overflow: hidden;
            user-select: none;
            pointer-events: none;
            font-weight: bold;

            @media speech {
                display: none;
            }
        }
    }

    &:hover,
    &:active {
        > span {
            color: #{map-get($colors, "primary", "middleDark")};
        }
    }

    &.active {
        > span {
            color: #{map-get($colors, "primary", "middleDark")};
            &::before {
                content: "";
                width: 100%;
                height: 7px;
                background: #{map-get($colors, "primary", "middleDark")};
                position: absolute;
                left: 0;
                bottom: -10px;
                transform: skewX(-25deg);
            }
        }
    }
}
