.bar {
  width: 100%;
}

.progress {
  height: 5px;
  -webkit-appearance: none;
  width: 100%;
  margin: 10px 0 10px;
  border-radius: 8px;
  background: #bababa;
  transition: background 0.2s ease;
  cursor: pointer;

  &::-webkit-slider-thumb {
    opacity: 0;
  }
}