@import 'src/themes/variables.module';
.footer-link-container {
    display: flex;
    flex-direction: column;
    flex: 1;

    a:hover {
        * {
            color: map-get($colors, 'primary', 'middleDark');
        }
    }

    > .footer-link-body {
        display: flex;
        flex-direction: column;
        flex: 1;

        > * {
            font-size: 13px;
        }
    }
}
