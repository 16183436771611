@import 'src/themes/mixins';

.authentication-form-submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

@include mobile-and-tablet {
    .authentication-form-submit-button-wrapper {
        display: flex;
        justify-content: flex-end;
    }
}