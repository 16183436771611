@import "src/themes/variables.module";

.teaser-card {
  width: 100%;
  padding-top: 133%;
  height: 0;
  position: relative;
  overflow: hidden;
}

.teaser-card-content {
  background-color: map-get($colors, 'greys', 'light');
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  // fix for flickering in Safari
  -webkit-transform: translateZ(0);
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;

  &.inverted {
    background-color: map-get($colors, 'greys', 'dark');
  }
}

.card-animation {
  transform: rotateY(.5turn); 
}