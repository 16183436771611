$colors: (
        primary: (
                accent: #21547F,
                lightAccent: #3280bf,
                dark: #426dfd,
                middleDark: #42aaff,
                middleLight: #93bbd0,
                light: #dae3e5
        ),
        greys: (
                dark: #000000,
                darkAccent: #292929,
                darkLight: #333333,
                middleDark: #808080,
                middle: #858585,
                middleLight: #b3b3b3,
                lightDark: #f2f2f2,
                lightAccent: #eeeeee,
                light: #ffffff
        ),
        categoryColors: (
                audio: #fa4166,
                art: #a2d902,
                eBook: #1ce0c6,
                book: #1ce0d6
        ),
        signalColors: (
                error: #ff0000,
                warning: #ff9900,
                success: #11f49d
        ),
        opacity: (
                dark50: rgba(0, 0, 0, 0.5),
                dark10: rgba(0, 0, 0, 0.1)
        ),
        other: (
                genreGreen: #b5b124
        )
);

$spacings: (
        small: 8px,
        medium: 16px,
        smallLarge: 20px,
        large: 24px
);

// TODO: cleanup breakpoints! naming? 
$breakpoints: (
        desktop: 1366px,
        desktopSmall: 1024px,
        mobile: 576px
);

$zIndices: (
        toasts: 120,
  //Messages should always be visible to user
        modals: 110,
  // Large windows with other content in the background
        pop-up-overlays: 80,
  //select-overlays etc.
        basic-elements: 70,
  //apply if needed to buttons, spans etc.
        low-level: 10
);

$transitions: (
        short: 100ms ease-in-out,
        medium: 200ms ease-in-out,
        long: 400ms ease-in-out
);

:export {
  @each $style-name, $style-content in $colors {
    @each $key, $value in $style-content {
      #{unquote($style-name + '_' + $key)}: $value;
    }
  }
}

:export {
  @each $key, $value in $breakpoints {
    #{unquote($key)}: $value;
  }
}
