@import 'src/themes/variables.module';
@import "src/themes/mixins";

.content {
  padding: map-get($spacings, 'smallLarge');
}

.animated-stages-section {
  position: relative;
  :global(.slick-dots) {
    bottom: 55px;
  }
}

.small {
  height: 250px;
}

.medium {
  height: 500px;
}

.large {
  height: 750px;
}

.full-height {
  height: 100vh;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.decButtonContainer {
  text-align: center;
  position: absolute;
  width: 150px;
  bottom: -80px;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  left: 0;
  z-index: 999;
}

.wrapper {
  p {
    @include body;
  }

  h1 {
    @include heading1;
  }

  h2 {
    @include heading2-bold;
  }

  h3 {
    @include heading3-bold;
  }

  h4 {
    @include heading4-bold;
  }

  h5 {
    @include heading5;
  }

  h6 {
    @include heading6;
  }
}

.inverted {
  color: map-get($colors, 'greys', 'light');

  .wrapper {
    p, h1, h2, h3, h4, h5, h6 {
      color: map-get($colors, 'greys', 'light');
    }
  }
}

.slider-inverted {
  :global(.slick-dots) li button:before {
    color: rgba(map-get($colors, 'greys', 'light'), .5);
  }
}

.withBackground {
  background-color: rgba(map-get($colors, 'greys', 'lightDark'), .5);

  &.inverted {
    background-color: rgba(map-get($colors, 'greys', 'darkLight'), .5);

    .wrapper {
      p, h1, h2, h3, h4, h5, h6 {
        background-color: rgba(map-get($colors, 'greys', 'darkLight'), .5);
      }
    }
  }
}