.audio-controls {
  display: flex;
  justify-content: space-between;
  width:  100%;
  margin: 10px auto 0;

  button {
    border: none;
    background: none;
    cursor: pointer;
  }
}

.audio-controls .play,
.audio-controls .pause {
  margin: 0 auto;
}

.audio-controls .prev svg,
.audio-controls .next svg {
  width: 30px;
  height: 30px;
}

.audio-controls .play svg,
.audio-controls .pause svg {
  height: 40px;
  width: 40px;
}
