@import "src/themes/mixins";

.text-section {
  .headline, .headline * {
    color: map-get($colors, "greys", "dark");
  }
}

.inverted {
  color: map-get($colors, "greys", "light");

  .headline, .headline * {
    color: map-get($colors, "greys", "light");
  }
}

.fullwidth {
  max-width: unset;
  width: 100%;
}

.fullwidth_margin {
  width: calc(100% - 32px);
  margin-left: auto;
  margin-right: auto;
}

.normal {
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
}

@include mobile-only {
  .text-section {
    .text {
      padding-top: 16px;
    }
  }
}