@import "src/themes/variables.module";

.overlay {
  height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: map-get($colors, 'greys', 'middleLight') transparent;
  color: white;
  background-color: black;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: map-get($colors, 'greys', 'middleLight');
  }

  > * {
    border-bottom: 1px solid white;
    margin: 0 16px;
    padding: 16px 0;

    > * {
      cursor: pointer;
      display: inline-block;
    }

    &:last-child {
      border-bottom: none;
    }

    a {
      text-decoration: none;
      color: white;
    }
  }

  .copy-icon-wrapper {
    margin-left: 5px;
  }
}