.volume {
  position: relative;
  margin: 0 0 0 15px;

  &:hover .slider {
    opacity: 1;
  }

  .slider {
    position: absolute;
    transform: rotate(270deg);
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
    width: 60px;
    left: -8px;
    top: -3px;
    opacity: 0;
    height: 5px;
    -webkit-appearance: none;
    margin: 10px 0 10px;
    border-radius: 8px;
    background: #bababa;
    cursor: pointer;

    &::-webkit-slider-thumb {
      opacity: 0;
    }
  }

  button {
    cursor: pointer;
    border: none;
    background: none;
    height: 100%;

    svg {
      vertical-align: middle;
    }
  }
}