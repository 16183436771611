@import "src/themes/mixins";

.link-base {
    background: unset;
    border: unset;
    margin: unset;
    padding: unset;
    text-decoration: none;
    font-weight: bold;
    color: map-get($colors, "greys", "dark");
    &:hover {
        color: map-get($colors, "primary", "middleDark");
    }

}

.inverted {
    .link-base{
        color: map-get($colors, "greys", "light");
        &:hover {
            color: map-get($colors, "primary", "middleDark");
        }
    }
}