@import "src/themes/mixins";

.modal-wrapper {
  display: inline-flex;
  position: fixed;
  z-index: 999;
  top: 120px;
  align-items: center;
  justify-content: center;

  width: 100%;
  box-sizing: border-box;

  @include mobile-and-tablet {
    margin: 0;
    right: 0;
    top : 90px;
    height: 90%;
  }
  @include desktop-and-smaller{
    top: 121px;
    height: 87%;
  }

  > .modal-container {
    position: absolute;
    overflow: visible;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;

    > .wrapper {
      display: flex;
      position: relative;

      > .close-wrapper {
        cursor: pointer;
        position: absolute;
        overflow: visible;
        z-index: 1000;
        display: flex;
        height: 50px;
        width: 50px;
        right: 1px;
        top: 0;
        @include mobile-and-tablet {
          right: -20px;
        }
      }
      
      .backdrop-wrapper {
        position: relative;
        display: flex;
        background-color: #000;
        clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 47.9px), calc(100% - 26.12px) 100%, 0px 100%);
        border: 1px solid #111;      
        padding: 10px;
        align-items: center;
        justify-content: center;
        right: 0;
        height: 100%;
        @include mobile-only {
          width: 88vw;
        }
        @include desktop-and-smaller {
          width: auto;
          max-width: 80vw;
        }
        &:before {
          content: '';
          position: absolute;
          height: 100%;
          width: 100%;
          background: #fff;
          clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 47.9px), calc(100% - 26.12px) 100%, 0px 100%);
        } 
        > .content {
          position: relative;
          display: flex;
          height: 95%;
          width: 100%;
          flex-direction: column;
          flex-wrap: nowrap;
          align-items: stretch;
          justify-content: center;
          align-content: center;
          overflow-y: auto;
          padding: 30px;
        }
      } 
    }
  }
}