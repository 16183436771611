@import "src/themes/variables.module";

.detail {
  display: flex;
  height: 100%;
  flex-flow: column;
  gap: 12px;
}

.content-wrapper {
  flex: 1;
  position: relative;
}

.content {
  overflow-y: scroll;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  scrollbar-color: map-get($colors, 'greys', 'middleLight') transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: map-get($colors, 'greys', 'middleLight');
  }
}

.attributes {
  margin: 0 -16px;
  padding: 12px 16px;
  background-color: map-get($colors, 'greys', 'lightDark');

  &.inverted{
    background-color: map-get($colors, 'greys', 'middleLight');
  }
}

.table {
  width: 100%;
}

.row {
  vertical-align: top;
}

.cell {
  font-size: 14px;
  &.inverted{
    color: map-get($colors, 'greys', 'dark');
  }
}

.cell-value {
  text-align: right;
}

.link-wrapper {
  text-align: right;
  padding-bottom: 5px;
}

.link {
  padding: 5px 0;

  &:hover {
    > * {
      color: map-get($colors, 'primary', 'middleDark') !important;
    }
  }
}

.icon {
  color: map-get($colors, 'greys', 'dark');
  margin-left: 4px;
}

.inverted {
  .description {
    color: map-get($colors, 'greys', 'light');
  }

  .link {
    > * {
      color: map-get($colors, 'greys', 'light');
    }
  }
}