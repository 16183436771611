@use "sass:math";
@import 'src/themes/variables.module';

@mixin basic-button {
    width: 100%;
    height: 50px;
    color: map-get($colors, white-100);
    background-color: map-get($colors, dark-grey);
    outline: transparent;
    border: none;
    border-radius: 5px;

    &:hover {
        background-color: map-get($colors, very-dark-grey);
    }
}

@mixin wallet-button {
    .wallet-button {
        @include basic-button();
        max-width: 300px;
        margin: 15px;
    }
}

@mixin mobile-only {
    @media (max-width: #{map-get($breakpoints, mobile) - 1}) {
        @content;
    }
}

@mixin tablet-only {
    @media (min-width: #{map-get($breakpoints, mobile)}) and (max-width: #{map-get($breakpoints, desktopSmall) - 1}) {
        @content;
    }
}

@mixin desktopSmall-only {
    @media (min-width: #{map-get($breakpoints, desktopSmall)}) and (max-width: #{map-get($breakpoints, desktop) - 1}) {
        @content;
    }
}

@mixin desktop-only {
    @media (min-width: #{map-get($breakpoints, desktop)}) {
        @content;
    }
}

// TODO: refactor mobile first desktop first mixins and use clean approach
@mixin mobile-and-tablet {
    @media (max-width: #{map-get($breakpoints, desktopSmall) - 1}) {
        @content;
    }
}

@mixin desktop-and-desktop-small {
    @media (min-width: #{map-get($breakpoints, desktopSmall)}) {
        @content;
    }
}


@mixin desktop-small-only {
    @media  (min-width: #{map-get($breakpoints, desktopSmall)}) and (max-width: #{map-get($breakpoints, desktop) - 1}) {
        @content;
    }
}

@mixin desktop-and-smaller {
    @media (min-width: #{map-get($breakpoints, mobile)}) {
        @content;
    }
}

@mixin smaller-than-max-content {
    @media (max-width: 1440px) {
        @content;
    }
}
// TODO-end: refactor mobile first desktop first mixins and use clean approach

@mixin bp-tablet {
    @media (min-width: #{map-get($breakpoints, mobile)}) {
        @content;
    }
}

@mixin bp-desktopSmall {
    @media (min-width: #{map-get($breakpoints, desktopSmall)}) {
        @content;
    }
}

@mixin bp-desktop {
    @media (min-width: #{map-get($breakpoints, desktop)}) {
        @content;
    }
}


// new mobile first breakpoint mixins


@mixin heading1 {
    font-family: cy;
    font-style: normal;
    font-weight: bold;

    letter-spacing: 1.2px;
    color: map-get($colors, 'greys', 'dark');
    font-size: 60px;
    line-height: 64px;

    @include mobile-only {
        font-size: 42px;
        line-height: 46px;
    }
}

@mixin heading2-bold {
    font-family: cy;
    font-style: normal;
    font-weight: bold;

    letter-spacing: 0.72px;
    color: map-get($colors, 'greys', 'dark');
    font-size: 48px;
    line-height: 56px;

    @include mobile-only {
        font-size: 34px;
        line-height: 42px;
    }
}

@mixin heading2-light {
    font-family: cy;
    font-style: normal;
    font-weight: 300;

    letter-spacing: 0.72px;
    color: map-get($colors, 'greys', 'dark');
    font-size: 48px;
    line-height: 56px;

    @include mobile-only {
        font-size: 34px;
        line-height: 42px;
    }
}

@mixin variant-heading {
    font-family: cy;
    font-style: normal;
    font-weight: bold;

    letter-spacing: 0.72px;
    color: map-get($colors, 'greys', 'dark');
    font-size: 30px;
    line-height: 41px;

    @include mobile-only {
        font-size: 33px;
        line-height: 41px;
    }
}

@mixin heading3-light {
    font-family: cy;
    font-style: normal;
    font-weight: 300;

    letter-spacing: 0.34px;
    color: map-get($colors, 'greys', 'dark');
    font-size: 34px;
    line-height: 36px;

    @include mobile-only {
        font-size: 24px;
        line-height: 26px;
    }
}

@mixin heading3-bold {
    font-family: cy;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.34px;
    color: map-get($colors, 'greys', 'dark');
    font-size: 34px;
    line-height: 36px;

    @include mobile-only {
        font-size: 24px;
        line-height: 26px;
    }
}

@mixin heading4-bold {
    font-family: cy;
    font-style: normal;
    font-weight: bold;

    letter-spacing: 0.17px;
    color: map-get($colors, 'greys', 'dark');
    font-size: 24px;
    line-height: 24px;

    @include mobile-only {
        font-size: 20px;
        line-height: 20px;
    }
}

@mixin heading4-light {
    font-family: cy;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.17px;
    color: map-get($colors, 'greys', 'dark');
    font-size: 24px;
    line-height: 24px;

    @include mobile-only {
        font-size: 20px;
        line-height: 20px;
    }
}

@mixin heading5 {
    font-family: cy;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: map-get($colors, 'greys', 'dark');
}

@mixin heading5-light {
    font-family: cy;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: map-get($colors, 'greys', 'dark');
}

@mixin heading6 {
    font-family: cy;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.27px;
    color: map-get($colors, 'greys', 'dark');
}

@mixin introtext {
    font-family: hero-new;
    font-style: normal;
    font-weight: 200;
    font-size: 22px;
    line-height: 36px;
    letter-spacing: 0.44px;
    color: map-get($colors, 'greys', 'darkLight');

    @include desktop-only {
        font-size: 22px;
        line-height: 36px;
    }

    @include mobile-only {
        font-size: 16px;
        line-height: 22px;
    }
}

@mixin toplineOrLabel {
    font-family: hero-new;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.18px;
    color: map-get($colors, 'greys', 'middleDark');
}

@mixin body {
    font-family: hero-new;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.18px;
    color: map-get($colors, 'greys', 'darkLight');
}

@mixin button {
    font-family: cy;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.27px;
    color: map-get($colors, 'greys', 'dark');
}

@mixin toast {
    font-family: hero-new;
    font-style: normal;
    letter-spacing: 0.27px;
    color: map-get($colors, 'greys', 'dark');
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
}

@mixin caption {
    font-family: hero-new;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.07px;
    color: map-get($colors, 'greys', 'middleDark');
}

@mixin input {
    font-family: hero-new;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.07px;
    color: map-get($colors, 'greys', 'middleLight');
}

@mixin quote {
    font-family: hero-new;
    font-style: italic;
    font-weight: 200;
    font-size: 22px;
    line-height: 36px;
    letter-spacing: 0.44px;
    color: map-get($colors, 'greys', 'darkLight');
}

@mixin link {
    color: map-get($colors, primary, dark);
    text-decoration: underline map-get($colors, primary, dark);
}

@mixin link-small {
    color: map-get($colors, greys, dark);
    text-decoration: none;
    font-size: 16px;

    &:hover {
        color: map-get($colors, primary, middleDark);
    }
}

@mixin input-label {
    > label {
        color: map-get($colors, 'greys', 'middle');
        display: flex;
        justify-content: space-between;
        padding-bottom: 8px;
    }
}

@mixin mobile-nav-link {
    font-family: hero-new;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0;
    color: map-get($colors, 'greys', 'dark');
}

@mixin table-title {
    font-family: hero-new;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: normal;
    letter-spacing: normal;
    color: map-get($colors, 'greys', 'middleDark');
}

@mixin table-value {
    font-family: hero-new;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: normal;
    letter-spacing: normal;
    color: map-get($colors, 'greys', 'dark');
}

@mixin keyword-title {
    font-family: hero-new;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: normal;
    letter-spacing: normal;
    color: map-get($colors, 'greys', 'light');
    text-transform: uppercase;
}

@mixin footer-paragraph {
    font-family: hero-new;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: normal;
    letter-spacing: normal;
    color: map-get($colors, 'greys', 'middleLight');
}

@mixin footer-link {
    font-family: hero-new;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 26px;
    letter-spacing: normal;
    color: map-get($colors, 'greys', 'middleLight');
}

@mixin quote-text {
    font-weight: 800;
    font-family: cy;
    font-size: 24px;
    line-height: 42px;
    letter-spacing: 0.24px;
    color: map-get($colors, 'greys', 'darkLight');
}

@mixin countdown {
    font-weight: bold;
    font-family: hero-new;
    font-size: 34px;
    line-height: 48px;
    letter-spacing: 0;
    color: map-get($colors, 'primary', 'middleDark');

    @include mobile-only {
        font-size: 24px;
        line-height: 26px;
    }
}

@mixin basic-input {
    font-family: inherit;
    font-size: 14px;
    color: map-get($colors, 'greys', 'dark');
    border: 1px solid map-get($colors, 'greys', 'middleLight');
    padding: 18px 16px;
    width: 100%;
    box-sizing: border-box;
    background-color: map-get($colors, 'greys', 'light');
    &::placeholder {
        color: map-get($colors, 'greys', 'middleLight');
        opacity: 1;
    }
    &:focus {
        outline: 1px solid map-get($colors, 'primary', 'middleDark');
    }
}

@mixin disabled-input {
    opacity: 0.5;

    * {
        cursor: default !important;
    }

    input,
    textarea {
        background-color: map-get($colors, 'greys', 'lightDark');
    }
}

@mixin inverted-input {
    input,
    textarea {
        &::placeholder {
            color: map-get($colors, 'greys', 'light');
            opacity: 1;
        }

        border: 1px solid map-get($colors, 'greys', 'light');
        color: map-get($colors, 'greys', 'light');
        background-color: map-get($colors, 'greys', 'dark');
    }
}

@mixin input-label-error {
    > label {
        color: map-get($colors, 'signalColors', 'error');
        display: flex;
        justify-content: space-between;
        padding-bottom: 8px;
    }
}

@mixin hexagon-shaped-box(
    $hex-min-width,
    $hex-height,
    $hex-distance,
    $border-width,
    $sqrt3
) {
    position: absolute;
    z-index: 1;

    top: 8px;
    left: 4px;

    display: flex;
    justify-content: center;
    align-items: center;
    appearance: none;

    min-height: 54px;
    min-width: $hex-min-width;
    height: $hex-height;
    margin: auto;
    padding: 0.5rem #{$hex-distance * 1.5};
    text-align: center;
    font-size: 1rem;
    line-height: 1rem;
    vertical-align: middle;
    color: #{map-get($colors, 'greys', 'dark')};
    cursor: pointer;
    border: none;

    > span {
        visibility: hidden;
    }

    clip-path: polygon(
        #{$hex-distance} 0,
        calc(100% - #{$hex-distance}) 0,
        100% 50%,
        calc(100% - #{$hex-distance}) 100%,
        #{$hex-distance} 100%,
        0 50%
    );

    > div:global(.withText) {
        margin-left: 15px;
    }

    > div {
        visibility: hidden;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #{map-get($colors, 'greys', 'dark')};
        clip-path: polygon(
            0 50%,
            #{$border-width} 50%,
            calc(#{$hex-distance} + #{math.div($border-width, 2)}) #{$border-width},
            calc(100% - #{$hex-distance} - #{math.div($border-width, 2)}) #{$border-width},
            calc(100% - #{$border-width}) 50%,
            calc(100% - #{$hex-distance} - #{math.div($border-width, 2)})
                calc(100% - #{$border-width}),
            calc(#{$hex-distance} + #{math.div($border-width, 2)})
                calc(100% - #{$border-width}),
            #{$border-width} 50%,
            0 50%,
            0 100%,
            100% 100%,
            100% 50%,
            100% 0,
            0 0
        );
    }
}

@mixin additional-attribute-form {
    .image-cell {
        width: 50px;

        .image {
            width: 50px;
        }
    }

    .additional-attribute-input {
        .attribute-column-item-last {
            width: 20%;
        }

        .attribute-column-item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            max-width: 400px;

            &:first-of-type,
            &:last-of-type {
                width: 20%;
            }

            &:nth-of-type(2) {
                width: 40%;
            }
        }

        .attribute-column-item-last {
            border-bottom: none;
        }

        .attribute-column {
            display: flex;
            justify-content: space-between;
            justify-items: flex-start;
            width: 100%;
            height: 50px;
            margin-bottom: 10px;
            padding-bottom: 5px;
            margin-top: #{map-get($spacings, 'smallLarge')};

            &:first-of-type {
                .attribute-column-item {
                    text-align: left;
                    border-bottom: 1px solid map-get($colors, secondary);
                }
            }

            &:nth-of-type(2) {
                height: auto;
                align-items: center;
            }
        }
    }
}

@mixin downloadable-file-box() {
    .downloadable-file-box {
        margin-bottom: 36px;

        .box-headline {
            margin-bottom: 7px;
        }

        .file-column {
            display: flex;
            align-items: flex-start;

            :first-child {
                margin-right: 14px;
                margin-top: 3px;
            }

            > :last-child {
                font-size: 16px;
                word-break: break-word;
                color: map-get($colors, primary, dark);
                text-decoration: underline map-get($colors, primary, dark);
            }

            .instance-detail-link {
                font-size: 16px;
                word-break: break-word;
                cursor: pointer;
                display: contents;

                @media (max-width: #{map-get($breakpoints, mobile)}) {
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }
    }
}

@mixin only-mobil-accordion-content() {
    @media (max-width: #{map-get($breakpoints, mobile)}) {
        width: 100%;

        .headline {
            display: none;
        }

        .description-body {
            margin-top: 0;
            font-size: 14px;
            line-height: 22px;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 16px;
            margin-bottom: 10px;
        }
    }
}

@mixin variant-instance-page() {
    .body-container {
        display: flex;
        width: 100%;

        .right-wrapper {
            width: 48%;
        }

        .left-wrapper {
            width: 41%;
            margin-right: 10%;
        }

        @media (max-width: #{map-get($breakpoints, mobile)}) {
            flex-direction: column;

            .right-wrapper,
            .left-wrapper {
                width: 100%;

                > * {
                    margin-bottom: 24px;
                }
            }
        }

        @media (min-width: #{map-get($breakpoints, desktop)}) {
            .right-wrapper,
            .left-wrapper {
                > * {
                    margin-bottom: 60px;
                }
            }
        }
    }

    .publisher-desktop {
        display: block;
    }

    .publisher-mobile {
        display: none;
    }

    @media (max-width: #{map-get($breakpoints, mobile)}) {
        .publisher-mobile {
            display: block;
        }

        .publisher-desktop {
            display: none;
        }
    }
}
