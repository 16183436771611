@import "src/themes/mixins";

.headline-section {
  * {
    color: map-get($colors, "greys", "dark");
  }

  .headline {
    padding-bottom: 19px;

    &.nospace {
      padding-bottom: 0;
    }

    a {
      text-decoration: none;
      color: map-get($colors, "greys", "darkLight");
    }

    a:hover {
      color: map-get($colors, 'primary', 'middleDark');
    }
  }
}

.subheader {
  padding-top: 12px;
}

.inverted {
  color: map-get($colors, "greys", "light");

  * {
    color: map-get($colors, "greys", "light");
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

@include mobile-only {
  .text {
    padding-top: 16px;
  }
}