@import "src/themes/variables.module";
@import "src/themes/mixins";

.multi {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.multi-item-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
}

.multi-item-placeholder {
  height: 0;
  padding: 0;
  width: 0;
  margin: 0;
}

.inverted {
  color: map-get($colors, "greys", "light");
}

.fullwidth {
  max-width: unset;
  width: 100%;
}

.fullwidth_margin {
  width: calc(100% - 32px);
  margin-left: auto;
  margin-right: auto;
}

.normal {
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
}

.multi-item-row-1 {
  flex: 1 1 100%;
}

.multi-item-row-2 {
  flex: 1 1 calc(50% - 20px);
}

.multi-item-row-3 {
  flex: 1 1 calc(33% - 20px);
}

.multi-item-row-4 {
  flex: 1 1 calc(25% - 20px);
}

.multi-item-row-5 {
  flex: 1 1 calc(20% - 20px);
}

.multi-item-row-6 {
  flex: 1 1 calc(16% - 20px);
}

.multi-item-row-7 {
  flex: 1 1 calc(14% - 20px);
}

.multi-item-row-8 {
  flex: 1 1 calc(13% - 20px);
}

@media (max-width: 1380px) {
  .multi-item-row-7, .multi-item-row-8 {
    flex: 1 1 calc(25% - 20px);
  }

  .multi-item-row-4, .multi-item-row-6 {
    flex: 1 1 calc(33% - 20px);
  }

  .multi-item-row-5 {
    flex: 1 1 calc(25% - 20px);
  }
}

@media (max-width: 1220px) {
  .multi-item-row-5 {
    flex: 1 1 calc(33% - 20px);
  }
}

@media (max-width: 930px) {
  .multi-item-row-7,
  .multi-item-row-8 {
    flex: 1 1 calc(33% - 20px);
  }

  .multi-item-row-3,
  .multi-item-row-4,
  .multi-item-row-5,
  .multi-item-row-6 {
    flex: 1 1 calc(50% - 20px);
  }
}

@media (max-width: 600px) {
  .multi-item-row-2,
  .multi-item-row-3,
  .multi-item-row-4,
  .multi-item-row-5,
  .multi-item-row-6,
  .multi-item-row-7,
  .multi-item-row-8 {
    flex: 1 1 46%;
  }
}

/*Tablet*/
@media (max-width: 768px) {
  .tablet-multi-item-row-1 {
    flex: 1 1 100%;
  }

  .tablet-multi-item-row-2 {
    flex: 1 1 calc(50% - 20px);
  }

  .tablet-multi-item-row-3 {
    flex: 1 1 calc(33% - 20px);
  }

  .tablet-multi-item-row-4 {
    flex: 1 1 calc(25% - 20px);
  }
}

/*Smartphone*/
@media (max-width: 425px) {
  .mobile-multi-item-row-1 {
    flex: 1 1 100%;
  }

  .mobile-multi-item-row-2 {
    flex: 1 1 calc(50% - 20px);
  }
}