@use "sass:math";
@import "src/themes/variables.module";

.accordion {
  .accordion-header {
    background-color: map-get($colors, greys, light);
    border: 1px solid map-get($colors, greys, middleLight);
    cursor: pointer;
    min-height: 55px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 18px;
    position: relative;
  }

  .accordion-label {
    padding-right: 55px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .accordion-icon-wrapper {
    padding-right: 8px;
    margin-left: auto;

    .accordion-icon {
      margin-right: 5px;
    }
  }

  .content {
    padding: 25px 18px;
    overflow: hidden;
    display: none;
    background-color: map-get($colors, greys, light);

    img {
      max-width: 200px;
    }

    @media (max-width: #{map-get($breakpoints, mobile)}) {
      flex-direction: column;

      img {
        margin-top: 10px;
        max-width: 100%;
      }
    }
  }

  .content-reverse {
    display: flex;
    flex-direction: column-reverse;
  }

  &.inverted {
    .content {
      background-color: #000;
    }
  }
}

.active {
  border: 1px solid map-get($colors, greys, middleLight);

  .accordion-header {
    border: none;
  }

  .content {
    display: block;
    height: 100%;
  }
}

.only-mobile {
  border: none;

  .accordion-header {
    display: none;
    padding-left: 0;
  }

  .content {
    padding: 0;
    display: block;
  }

  @media (max-width: #{map-get($breakpoints, mobile)}) {
    .content {
      padding: 25px 18px;
      overflow: hidden;
      display: none;
      background-color: map-get($colors, greys, light);
    }

    border: 1px solid map-get($colors, greys, middleLight);

    .accordion-header {
      display: flex;
      padding-left: 18px;
    }
  }
}

.active {
  @media (max-width: #{map-get($breakpoints, mobile)}) {
    border: 1px solid map-get($colors, greys, middleLight);

    .accordion-header {
      border: none;
    }

    .content {
      display: block;
      height: 100%;
    }
    
    .content-reverse {
      display: flex;
      height: 100%;
    }
  }
}
