@import "src/themes/mixins";

.news-academy-list, .news-academyFilter-list {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;

  .news-item {
    flex: 1 0 100%;

    @media(min-width: 48rem) {
      flex: 1 0 40%;
    }

    @media(min-width: 72rem) {
      flex: 1 0 30%;
    }
  }
}

.category-container {
  padding-bottom: 2rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 1rem;
  max-width: 35rem;
  margin: 0 auto;

  .select {
    flex: 1;
  }

  button {
    margin-top: 32px;
  }
}

.inverted {
  color: map-get($colors, "greys", "light");

  * {
    color: map-get($colors, "greys", "light");
  }
}

.fullwidth {
  max-width: unset;
  width: 100%;
}

.fullwidth_margin {
  width: calc(100% - 32px);
  margin-left: auto;
  margin-right: auto;
}

.normal {
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
}