@use "sass:math";
@import "src/themes/variables.module";
@import "src/themes/mixins";

$border-width: 1px;
$sqrt3: 1.7321; // Square-root of 3
$hex-height: 3rem;
$hex-min-width: math.div($hex-height * $sqrt3, 2);
$hex-distance: (math.div($hex-height, 2 * $sqrt3)); // d = h / (2*tan(60°)); with h = the height of the button

.button-container {
  text-decoration: none !important;
}

.button {
  background: unset;
  border: none;
  outline: none;
  margin: 0 30px 15px 15px;

  &:disabled {
    cursor: default !important;
  }
}

.primary {
  background: transparent;
  border: none;
  position: relative;

  > div:not(.shadow) {
    z-index: 2;

    display: flex;
    justify-content: center;
    align-items: center;
    appearance: none;
    position: relative;
    min-height: 54px;
    min-width: $hex-min-width;
    height: $hex-height;
    margin: auto;
    padding: 0.5rem #{$hex-distance * 1.5};
    text-align: center;
    font-size: 1rem;
    line-height: 1rem;
    vertical-align: middle;
    color: #{map-get($colors, "greys", "light")};
    background-color: #{map-get($colors, "greys", "dark")};
    cursor: pointer;
    border: none;

    > span {
      color: #{map-get($colors, "greys", "light")};
    }

    clip-path: polygon(
                    #{$hex-distance} 0,
                    calc(100% - #{$hex-distance}) 0,
                    100% 50%,
                    calc(100% - #{$hex-distance}) 100%,
                    #{$hex-distance} 100%,
                    0 50%
    );

    > div:global(.withText) {
      margin-left: 15px;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #{map-get($colors, "greys", "dark")};
      clip-path: polygon(
                      0 50%,
                      #{$border-width} 50%,
                      calc(#{$hex-distance} + #{math.div($border-width, 2)}) #{$border-width},
                      calc(100% - #{$hex-distance} - #{math.div($border-width, 2)}) #{$border-width},
                      calc(100% - #{$border-width}) 50%,
                      calc(100% - #{$hex-distance} - #{math.div($border-width, 2)}) calc(100% - #{$border-width}),
                      calc(#{$hex-distance} + #{math.div($border-width, 2)}) calc(100% - #{$border-width}),
                      #{$border-width} 50%,
                      0 50%,
                      0 100%,
                      100% 100%,
                      100% 50%,
                      100% 0,
                      0 0
      );
    }
  }

  > .shadow {
    @include hexagon-shaped-box($hex-min-width, $hex-height, $hex-distance, $border-width, $sqrt3);
    width: 100%;
    height: 100%;
  }

  &:hover:not(:disabled) {
    > div:not(.shadow) {
      color: #{map-get($colors, "primary", "middleDark")};

      > span {
        color: #{map-get($colors, "primary", "middleDark")};
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #{map-get($colors, "primary", "middleDark")};
      }
    }

    .shadow {
      background-color: #{map-get($colors, "primary", "middleDark")};
    }
  }

  &:disabled {
    > div:not(.shadow) {
      cursor: default;
      background-color: #{map-get($colors, "greys", "middleLight")};

      > span {
        color: #{map-get($colors, "greys", "light")};
      }

      clip-path: polygon(
                      #{$hex-distance} 0,
                      calc(100% - #{$hex-distance}) 0,
                      100% 50%,
                      calc(100% - #{$hex-distance}) 100%,
                      #{$hex-distance} 100%,
                      0 50%
      );

      > div:global(.withText) {
        margin-left: 15px;
      }

      &::before {
        background-color: unset;
      }
    }

    > .shadow {
      color: #{map-get($colors, "greys", "middleLight")};
      cursor: default;

      &::before {
        background-color: #{map-get($colors, "greys", "middleLight")};
      }
    }
  }

  .not-pending {
    padding: 0 16px;
  }

  .loading-spinner {
    margin-left: 14px;
  }
}

.primary.inverted {
  > div:not(.shadow) {
    &::before {
      background-color: #{map-get($colors, "greys", "light")};
    }
  }

  > .shadow {
    background-color: #{map-get($colors, "greys", "light")};

    &::before {
      background-color: unset;
    }
  }

  &:hover:not(:disabled) {
    > div:not(.shadow) {
      &::before {
        background-color: #{map-get($colors, "primary", "middleDark")};
      }
    }

    > .shadow {
      background-color: #{map-get($colors, "primary", "middleDark")};
    }
  }
}

.secondary-filled.inverted {
  color: map-get($colors, "greys", "dark");
  background-color: map-get($colors, "greys", "light");
}

.selected {
  background: transparent;
  border: none;
  position: relative;

  > div:not(.shadow) {
    z-index: 2;

    display: flex;
    appearance: none;
    align-items: center;
    position: relative;
    min-height: 54px;
    min-width: $hex-min-width;
    height: $hex-height;
    padding: 0.5rem #{$hex-distance * 1.5};
    font-size: 1rem;
    line-height: 1rem;
    vertical-align: middle;
    color: #{map-get($colors, "greys", "light")};
    background-color: #{map-get($colors, "greys", "dark")};
    cursor: pointer;
    border: none;

    > span {
      color: #{map-get($colors, "greys", "light")};
    }

    clip-path: polygon(#{$hex-distance} 0, 100% 0, 100% 100%, #{$hex-distance} 100%, 0 50%);

    > div:global(.withText) {
      margin-left: 15px;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #{map-get($colors, "greys", "dark")};
      clip-path: polygon(
                      0 50%,
                      #{$border-width} 50%,
                      calc(#{$hex-distance} + #{math.div($border-width, 2)}) #{$border-width},
                      calc(100% - #{$hex-distance} - #{math.div($border-width, 2)}) #{$border-width},
                      calc(100% - #{$border-width}) 50%,
                      calc(100% - #{$hex-distance} - #{math.div($border-width, 2)}) calc(100% - #{$border-width}),
                      calc(#{$hex-distance} + #{math.div($border-width, 2)}) calc(100% - #{$border-width}),
                      #{$border-width} 50%,
                      0 50%,
                      0 100%,
                      100% 100%,
                      100% 50%,
                      100% 0,
                      0 0
      );
    }
  }

  > .shadow {
    position: absolute;
    z-index: 1;

    top: 8px;
    left: 4px;

    display: flex;
    justify-content: center;
    align-items: center;
    appearance: none;

    min-height: 54px;
    min-width: $hex-min-width;
    height: $hex-height;
    margin: auto;
    padding: 0.5rem #{$hex-distance * 1.5};
    text-align: center;
    font-size: 1rem;
    line-height: 1rem;
    vertical-align: middle;
    color: #{map-get($colors, "greys", "dark")};
    cursor: pointer;
    border: none;

    > span {
      visibility: hidden;
    }

    clip-path: polygon(#{$hex-distance} 0, 100% 0, 100% 100%, #{$hex-distance} 100%, 0 50%);

    > div:global(.withText) {
      margin-left: 15px;
    }

    > div {
      visibility: hidden;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #{map-get($colors, "greys", "dark")};

      clip-path: polygon(
                      0 50%,
                      #{$border-width} 50%,
                      calc(#{$hex-distance} + #{math.div($border-width, 2)}) #{$border-width},
                      calc(100% - #{$border-width}) #{$border-width},
                      calc(100% - #{$border-width}) calc(100% - #{$border-width}),
                      100% calc(100% - #{$border-width}),
                      calc(#{$hex-distance} + #{math.div($border-width, 2)}) calc(100% - #{$border-width}),
                      #{$border-width} 50%,
                      0 50%,
                      0 100%,
                      100% 100%,
                      100% 50%,
                      100% 0,
                      0 0
      );
    }

    width: 100%;
    height: 100%;
  }

  &:disabled {
    > div:not(.shadow) {
      background-color: #{map-get($colors, "greys", "middleLight")};

      > span {
        color: #{map-get($colors, "greys", "light")};
      }

      clip-path: polygon(
                      #{$hex-distance} 0,
                      calc(100% - #{$hex-distance}) 0,
                      100% 50%,
                      calc(100% - #{$hex-distance}) 100%,
                      #{$hex-distance} 100%,
                      0 50%
      );

      > div:global(.withText) {
        margin-left: 15px;
      }

      &::before {
        background-color: unset;
      }
    }

    > .shadow {
      color: #{map-get($colors, "greys", "middleLight")};

      &::before {
        background-color: #{map-get($colors, "greys", "middleLight")};
      }
    }
  }
}

.selected.inverted {
  > div:not(.shadow) {
    &::before {
      background-color: #{map-get($colors, "greys", "light")};
    }
  }

  > .shadow {
    background-color: #{map-get($colors, "greys", "light")};

    &::before {
      background-color: unset;
    }
  }

  &:hover {
    > div:not(.shadow) {
      &::before {
        background-color: #{map-get($colors, "primary", "middleDark")};
      }
    }

    > .shadow {
      background-color: #{map-get($colors, "primary", "middleDark")};
    }
  }
}

.secondary-outlined {
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  position: relative;
  min-height: 54px;
  min-width: $hex-min-width;
  height: $hex-height;
  margin: auto;
  padding: 0.5rem #{$hex-distance * 1.5};
  text-align: center;
  font-size: 1rem;
  line-height: 1rem;
  vertical-align: middle;
  color: #{map-get($colors, "greys", "dark")};
  cursor: pointer;
  border: none;

  > span {
    color: #{map-get($colors, "greys", "dark")};
  }

  clip-path: polygon(
                  #{$hex-distance} 0,
                  calc(100% - #{$hex-distance}) 0,
                  100% 50%,
                  calc(100% - #{$hex-distance}) 100%,
                  #{$hex-distance} 100%,
                  0 50%
  );

  > div:global(.withText.right) {
    margin-left: 15px;

    bottom: 8px;
    left: 21px;
  }

  > div:global(.withText.left) {
    margin-right: 15px;

    bottom: 8px;
    right: 21px;
  }

  > div:global(.withText) {
    display: flex;
    justify-content: center;
    align-items: center;
    appearance: none;
    position: relative;
    min-height: 54px;
    min-width: $hex-min-width;
    height: $hex-height;
    margin: auto;
    padding: 0.5rem #{$hex-distance * 1.5};
    text-align: center;
    font-size: 1rem;
    line-height: 1rem;
    vertical-align: middle;
    color: #{map-get($colors, "greys", "dark")};
    cursor: pointer;
    border: none;

    clip-path: polygon(
                    #{$hex-distance} 0,
                    calc(100% - #{$hex-distance}) 0,
                    100% 50%,
                    calc(100% - #{$hex-distance}) 100%,
                    #{$hex-distance} 100%,
                    0 50%
    );

    height: calc(100% + calc(0.5rem * 2));

    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #{map-get($colors, "greys", "dark")};
      clip-path: polygon(
                      0 50%,
                      #{$border-width} 50%,
                      calc(#{$hex-distance} + #{math.div($border-width, 2)}) #{$border-width},
                      calc(100% - #{$hex-distance} - #{math.div($border-width, 2)}) #{$border-width},
                      calc(100% - #{$border-width}) 50%,
                      calc(100% - #{$hex-distance} - #{math.div($border-width, 2)}) calc(100% - #{$border-width}),
                      calc(#{$hex-distance} + #{math.div($border-width, 2)}) calc(100% - #{$border-width}),
                      #{$border-width} 50%,
                      0 50%,
                      0 100%,
                      100% 100%,
                      100% 50%,
                      100% 0,
                      0 0
      );
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #{map-get($colors, "greys", "dark")};
    clip-path: polygon(
                    0 50%,
                    #{$border-width} 50%,
                    calc(#{$hex-distance} + #{math.div($border-width, 2)}) #{$border-width},
                    calc(100% - #{$hex-distance} - #{math.div($border-width, 2)}) #{$border-width},
                    calc(100% - #{$border-width}) 50%,
                    calc(100% - #{$hex-distance} - #{math.div($border-width, 2)}) calc(100% - #{$border-width}),
                    calc(#{$hex-distance} + #{math.div($border-width, 2)}) calc(100% - #{$border-width}),
                    #{$border-width} 50%,
                    0 50%,
                    0 100%,
                    100% 100%,
                    100% 50%,
                    100% 0,
                    0 0
    );
  }

  &:hover:not(:disabled) {
    color: #{map-get($colors, "primary", "middleDark")};

    > div:global(.withText) {
      > svg {
        color: #{map-get($colors, "primary", "middleDark")};
      }

      &::before {
        background-color: #{map-get($colors, "primary", "middleDark")};
      }
    }

    > span {
      color: #{map-get($colors, "primary", "middleDark")};
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #{map-get($colors, "primary", "middleDark")};
    }
  }

  &:disabled {
    color: #{map-get($colors, "greys", "middleDark")};

    > span {
      color: #{map-get($colors, "greys", "middleDark")};
    }

    &::before {
      background-color: #{map-get($colors, "greys", "middleDark")};
    }

    > div:global(.withText) {
      color: #{map-get($colors, "greys", "middleDark")};

      &::before {
        background-color: #{map-get($colors, "greys", "middleDark")};
      }
    }
  }
}

.secondary-outlined.inverted {
  &:disabled {
    color: #{map-get($colors, "greys", "middleDark")};

    > span {
      color: #{map-get($colors, "greys", "middleDark")};
    }

    &::before {
      background-color: #{map-get($colors, "greys", "middleDark")};
    }

    > div:global(.withText) {
      color: #{map-get($colors, "greys", "middleDark")};

      &::before {
        background-color: #{map-get($colors, "greys", "middleDark")};
      }
    }
  }

  color: #{map-get($colors, "greys", "light")};

  > div:global(.withText) {
    color: #{map-get($colors, "greys", "light")};

    &::before {
      background-color: #{map-get($colors, "greys", "light")};
    }
  }

  > span {
    color: #{map-get($colors, "greys", "light")};
  }

  &::before {
    background-color: #{map-get($colors, "greys", "light")};
  }

  &:hover:not(:disabled) {
    color: #{map-get($colors, "primary", "middleDark")};

    > span {
      color: #{map-get($colors, "primary", "middleDark")};
    }

    &::before {
      background-color: #{map-get($colors, "primary", "middleDark")};
    }

    > div:global(.withText) {
      color: #{map-get($colors, "primary", "middleDark")};

      &::before {
        background-color: #{map-get($colors, "primary", "middleDark")};
      }
    }
  }
}

.secondary-filled {
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  position: relative;
  min-height: 54px;
  min-width: $hex-min-width;
  height: $hex-height;
  margin: auto;
  padding: 0.5rem #{$hex-distance * 1.5};
  text-align: center;
  font-size: 1rem;
  line-height: 1rem;
  vertical-align: middle;
  background-color: #{map-get($colors, "greys", "dark")};
  color: #{map-get($colors, "greys", "light")};
  cursor: pointer;
  border: none;

  > span {
    color: #{map-get($colors, "greys", "light")};
  }

  clip-path: polygon(
                  #{$hex-distance} 0,
                  calc(100% - #{$hex-distance}) 0,
                  100% 50%,
                  calc(100% - #{$hex-distance}) 100%,
                  #{$hex-distance} 100%,
                  0 50%
  );

  > div:global(.withText) {
    margin-left: 15px;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #{map-get($colors, "greys", "dark")};
    clip-path: polygon(
                    0 50%,
                    #{$border-width} 50%,
                    calc(#{$hex-distance} + #{math.div($border-width, 2)}) #{$border-width},
                    calc(100% - #{$hex-distance} - #{math.div($border-width, 2)}) #{$border-width},
                    calc(100% - #{$border-width}) 50%,
                    calc(100% - #{$hex-distance} - #{math.div($border-width, 2)}) calc(100% - #{$border-width}),
                    calc(#{$hex-distance} + #{math.div($border-width, 2)}) calc(100% - #{$border-width}),
                    #{$border-width} 50%,
                    0 50%,
                    0 100%,
                    100% 100%,
                    100% 50%,
                    100% 0,
                    0 0
    );
  }

  &:hover:not(:disabled) {
    color: #{map-get($colors, "primary", "middleDark")};

    > span {
      color: #{map-get($colors, "primary", "middleDark")};
    }
  }

  &:disabled {
    background-color: #{map-get($colors, "greys", "middleDark")};

    &::before {
      background-color: unset;
    }
  }

  .not-pending {
    padding: 0 16px;
  }

  .loading-spinner {
    margin-left: 14px;
  }
}

.primary:hover {
  > * {
    color: map-get($colors, "primary", "middleDark");
  }

  > :global(.buttonShadow) {
    background-color: map-get($colors, "primary", "middleDark");

    > div {
      > div {
        > div {
          border-left-color: map-get($colors, "primary", "middleDark");
        }
      }
    }
  }
}

.stage {
  z-index: 3;
  cursor: default;
}

.icon-container {
  width: 18px;
  height: 18px;
}

.small-p {
  padding: 1px !important;
}

.shadow {
  z-index: 2;
  position: absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  background-color: white;

  clip-path: polygon(
                  calc(#{$hex-distance} + 5px) 0,
                  calc(100% - calc(#{$hex-distance} + 5px)) 0,
                  100% 50%,
                  calc(100% - calc(#{$hex-distance} + 5px)) 100%,
                  calc(#{$hex-distance} + 5px) 100%,
                  0 50%
  );
}

.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  position: relative;
  min-height: 54px;
  min-width: $hex-min-width;
  height: $hex-height;
  margin-top: auto;
  margin-bottom: auto;
  padding: 0.5rem #{$hex-distance * 1.5};
  text-align: center;
  font-size: 1rem;
  line-height: 1rem;
  vertical-align: middle;
  background-color: unset;
  color: #{map-get($colors, "greys", "light")};
  cursor: pointer;
  border: none;

  clip-path: polygon(
                  #{$hex-distance} 0,
                  calc(100% - #{$hex-distance}) 0,
                  100% 50%,
                  calc(100% - #{$hex-distance}) 100%,
                  #{$hex-distance} 100%,
                  0 50%
  );

  &:checked {
    background-color: #{map-get($colors, "primary", "dark")};
  }
}

.secondary-outlined-filled-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  position: relative;
  min-height: 54px;
  min-width: $hex-min-width;
  height: $hex-height;
  margin: auto;
  padding: 0.5rem #{$hex-distance * 1.5};
  text-align: center;
  font-size: 1rem;
  line-height: 1rem;
  vertical-align: middle;
  color: #{map-get($colors, "greys", "dark")};
  cursor: pointer;
  border: none;

  > span {
    color: #{map-get($colors, "greys", "dark")};
  }

  clip-path: polygon(
                  #{$hex-distance} 0,
                  calc(100% - #{$hex-distance}) 0,
                  100% 50%,
                  calc(100% - #{$hex-distance}) 100%,
                  #{$hex-distance} 100%,
                  0 50%
  );

  > div:global(.withText.right) {
    margin-left: 15px;

    bottom: 8px;
    left: 21px;
  }

  > div:global(.withText.left) {
    margin-right: 15px;

    bottom: 8px;
    right: 21px;
  }

  > div:global(.withText) {
    display: flex;
    justify-content: center;
    align-items: center;
    appearance: none;
    position: relative;
    min-height: 54px;
    min-width: $hex-min-width;
    height: $hex-height;
    margin: auto;
    padding: 0.5rem #{$hex-distance * 1.5};
    text-align: center;
    font-size: 1rem;
    line-height: 1rem;
    vertical-align: middle;
    color: #{map-get($colors, "greys", "dark")};
    cursor: pointer;
    border: none;

    clip-path: polygon(
                    #{$hex-distance} 0,
                    calc(100% - #{$hex-distance}) 0,
                    100% 50%,
                    calc(100% - #{$hex-distance}) 100%,
                    #{$hex-distance} 100%,
                    0 50%
    );

    > svg,
    > svg > path {
      color: map-get($colors, "greys", "light");
      position: relative;
    }

    height: calc(100% + calc(0.5rem * 2));

    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #{map-get($colors, "greys", "dark")};
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #{map-get($colors, "greys", "dark")};
    clip-path: polygon(
                    0 50%,
                    #{$border-width} 50%,
                    calc(#{$hex-distance} + #{math.div($border-width, 2)}) #{$border-width},
                    calc(100% - #{$hex-distance} - #{math.div($border-width, 2)}) #{$border-width},
                    calc(100% - #{$border-width}) 50%,
                    calc(100% - #{$hex-distance} - #{math.div($border-width, 2)}) calc(100% - #{$border-width}),
                    calc(#{$hex-distance} + #{math.div($border-width, 2)}) calc(100% - #{$border-width}),
                    #{$border-width} 50%,
                    0 50%,
                    0 100%,
                    100% 100%,
                    100% 50%,
                    100% 0,
                    0 0
    );
  }

  &:hover:not(:disabled) {
    color: #{map-get($colors, "primary", "middleDark")};

    > div:global(.withText) {
      > svg {
        color: #{map-get($colors, "primary", "middleDark")};
      }

      &::before {
        background-color: #{map-get($colors, "primary", "middleDark")};
      }
    }

    > span {
      color: #{map-get($colors, "primary", "middleDark")};
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #{map-get($colors, "primary", "middleDark")};
    }
  }

  &:disabled {
    color: #{map-get($colors, "greys", "middleDark")};

    > span {
      color: #{map-get($colors, "greys", "middleDark")};
    }

    &::before {
      background-color: #{map-get($colors, "greys", "middleDark")};
    }

    > div:global(.withText) {
      color: #{map-get($colors, "greys", "middleDark")};

      &::before {
        background-color: #{map-get($colors, "greys", "middleDark")};
      }
    }
  }
}

.secondary-outlined-filled-icon.inverted {
  &:disabled {
    color: #{map-get($colors, "greys", "middleDark")};

    > span {
      color: #{map-get($colors, "greys", "middleDark")};
    }

    &::before {
      background-color: #{map-get($colors, "greys", "middleDark")};
    }

    > div:global(.withText) {
      color: #{map-get($colors, "greys", "middleDark")};

      &::before {
        background-color: #{map-get($colors, "greys", "middleDark")};
      }
    }
  }

  color: #{map-get($colors, "greys", "light")};

  > div:global(.withText) {
    color: #{map-get($colors, "greys", "light")};

    &::before {
      background-color: #{map-get($colors, "greys", "light")};
    }
  }

  > span {
    color: #{map-get($colors, "greys", "light")};
  }

  &::before {
    background-color: #{map-get($colors, "greys", "light")};
  }

  &:hover:not(:disabled) {
    color: #{map-get($colors, "primary", "middleDark")};

    > span {
      color: #{map-get($colors, "primary", "middleDark")};
    }

    &::before {
      background-color: #{map-get($colors, "primary", "middleDark")};
    }

    > div:global(.withText) {
      color: #{map-get($colors, "primary", "middleDark")};

      &::before {
        background-color: #{map-get($colors, "primary", "middleDark")};
      }
    }
  }
}

.left-arrow {
  background: transparent;
  border: none;
  position: relative;
  cursor: pointer;

  > div:not(.shadow) {
    z-index: 2;

    display: flex;
    appearance: none;
    align-items: center;
    position: relative;
    min-height: 54px;
    min-width: $hex-min-width;
    height: $hex-height;
    padding: 0.5rem #{$hex-distance * 1.5};
    font-size: 1rem;
    line-height: 1rem;
    vertical-align: middle;
    color: #{map-get($colors, "greys", "light")};
    background-color: #{map-get($colors, "greys", "dark")};
    border: none;

    > span {
      color: #{map-get($colors, "greys", "light")};
    }

    clip-path: polygon(#{$hex-distance} 0, 100% 0, 100% 100%, #{$hex-distance} 100%, 0 50%);

    > div:global(.withText.left) {
      margin-right: 15px;
    }

    > div:global(.withText.right) {
      margin-left: 15px;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #{map-get($colors, "greys", "dark")};
      clip-path: polygon(
                      0 50%,
                      #{$border-width} 50%,
                      calc(#{$hex-distance} + #{math.div($border-width, 2)}) #{$border-width},
                      calc(100% - #{$hex-distance} - #{math.div($border-width, 2)}) #{$border-width},
                      calc(100% - #{$border-width}) 50%,
                      calc(100% - #{$hex-distance} - #{math.div($border-width, 2)}) calc(100% - #{$border-width}),
                      calc(#{$hex-distance} + #{math.div($border-width, 2)}) calc(100% - #{$border-width}),
                      #{$border-width} 50%,
                      0 50%,
                      0 100%,
                      100% 100%,
                      100% 50%,
                      100% 0,
                      0 0
      );
    }
  }

  &:disabled {
    > div:not(.shadow) {
      background-color: #{map-get($colors, "greys", "middleLight")};

      > span {
        color: #{map-get($colors, "greys", "light")};
      }

      clip-path: polygon(
                      #{$hex-distance} 0,
                      calc(100% - #{$hex-distance}) 0,
                      100% 50%,
                      calc(100% - #{$hex-distance}) 100%,
                      #{$hex-distance} 100%,
                      0 50%
      );

      > div:global(.withText) {
        margin-left: 15px;
      }

      &::before {
        background-color: unset;
      }
    }
  }
}

.left-arrow.inverted {
  > div:not(.shadow) {
    &::before {
      background-color: #{map-get($colors, "greys", "light")};
    }
  }

  &:hover {
    > div:not(.shadow) {
      &::before {
        background-color: #{map-get($colors, "primary", "middleDark")};
      }
    }
  }
}