@import 'src/themes/mixins';

.select-wrapper {

    .input-label {
        @include input-label();
    }

    .input-label-error {
        @include input-label-error();
    }

    .select {
        .dropdown-indicator {
            width: 30px;
            height: 30px;
        }

        .dropdown-indicator.open {
            padding-left: 10px;
        }

        .dropdown-indicator.closed {
            padding-left: 8px;
        }
    }
}

.select-wrapper.disabled {
    @include disabled-input();
}
