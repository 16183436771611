@import 'src/themes/variables.module';
@import 'src/themes/mixins';

.pdf {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;

  :global(.rpv-core__minimal-button--selected) {
    background-color: map-get($colors, 'greys', 'dark');
  }

  :global(.rpv-thumbnail__item--selected),
  :global(.rpv-thumbnail__item:focus),
  :global(.rpv-thumbnail__item:hover) {
    background-color: map-get($colors, 'greys', 'darkAccent');
  }

  :global(.rpv-bookmark__item:focus),
  :global(.rpv-bookmark__item:hover) {
    background-color: map-get($colors, 'greys', 'darkAccent');
  }

  :global(.rpv-core__progress-bar-progress) {
    color: map-get($colors, 'greys', 'light');
    background-color: map-get($colors, 'greys', 'darkAccent');
  }
}

.toolbar-left {
  align-items: center;
  display: flex;
}

.toolbar-center {
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: center;
}

.toolbar-right {
  align-items: center;
  display: flex;
  margin-left: auto;
}

.toolbar-item {
  display: inline-flex;
  vertical-align: middle;
  padding: 0 2px;
  color: map-get($colors, 'greys', 'light');
}

.loader {
  width: 100%;
  max-width: 240px;
}

.error-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.error {
  background-color: map-get($colors, 'signalColors', 'error');
  border-radius: 0.25rem;
  color: map-get($colors, 'greys', 'light');
  padding: 0.5rem;
}

.inverted {
  .toolbar-item {
    color: map-get($colors, 'greys', 'dark');
  }

  :global(.rpv-core__minimal-button--selected) {
    background-color: map-get($colors, 'opacity', 'dark10');
  }

  :global(.rpv-thumbnail__item--selected),
  :global(.rpv-thumbnail__item:focus),
  :global(.rpv-thumbnail__item:hover) {
    background-color: map-get($colors, 'greys', 'lightAccent');
  }

  :global(.rpv-core__splitter) {
    background-color: map-get($colors, 'greys', 'light');
  }

  :global(.rpv-default-layout__sidebar--ltr) {
    border-right: 1px solid map-get($colors, 'greys', 'lightAccent');
  }

  :global(.rpv-bookmark__item:focus),
  :global(.rpv-bookmark__item:hover) {
    background-color: map-get($colors, 'greys', 'lightAccent');
  }

  :global(.rpv-core__progress-bar-progress) {
    color: map-get($colors, 'greys', 'dark');
    background-color: map-get($colors, 'greys', 'lightAccent');
  }
}

.fullwidth {
  max-width: unset;
  width: 100%;
}

.fullwidth_margin {
  width: calc(100% - 32px);
  margin-left: auto;
  margin-right: auto;
}

.normal {
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 430px) {
  .pdf {
    :global(.pdf_toolbar-item__LCVUM) {
      padding: 0;
    }
  }

  .hide-mobile {
    display: none;
  }
}