@import 'src/themes/variables.module';

.header-background {
    position: absolute;
    left: 0;
    width: 100%;
    height: 120px;
    z-index: map-get($zIndices, 'low-level');
    background-image: url("~/src/assets/header_background.png");
}
