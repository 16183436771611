@import "src/themes/mixins";
.hexagon-wrapper {
  margin: auto;
  display: flex;
  text-align: left;
  text-align: initial;
  width: 64px;
  height: 65px;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  @include desktop-and-desktop-small {
  right: -17px;
  top: -31px;
  }
  @include mobile-and-tablet {
    right: 3px;
    top: -31px;
  }

.hexagon {
  position: relative;
  width: 46.8%;
  height: 80.5%;
  margin: auto;
  color: #fff;
  background: #111;
  display: flex;
  align-content: center;
  justify-content: center;

  &:before,&:after {
  position: absolute;
  content: "";
  background: inherit;
  height: 100%;
  width: 100%;
  border-radius: 0;
  }
  &:before {
    transform: rotateZ(60deg);
  }
  &:after {
    transform: rotateZ(-60deg);
  }

  .content {
    top: 16px;
    display: flex ;
    position: relative;
    align-content: center;
    justify-content: center;
    border: none;
    z-index: 1;
    &:hover,
    &:active {
        > svg 
        >path {
            fill: #{map-get($colors, "primary", "middleDark")};
        }
    }

  }
}
}