@import 'src/themes/variables.module';

.default-header-mobile-navigation {
    width: 260px;
    > div {
        width: 260px;
    }
    position: fixed;
    right: 23.9px !important;
    top: 45px;
    display: inline-block;
    z-index: 99999999;
}
