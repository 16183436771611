@import 'src/themes/mixins';
.authentification-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    flex-wrap: nowrap;
    align-items: stretch;
    > * {
        margin: 10px 0;
    }

    .create-wallet-link {
        padding-top: 40px;
    }

    .register-link {
        display: flex;
        justify-content: flex-end;
        margin: 5px;
    }

    .forgot-password-link {
        margin-top: -8px;
    }
    .submit-button {
        display: flex;
        justify-content: flex-end;
        >:nth-child(1) {
        justify-content: flex-end;
        }
    }
}

@include mobile-only {
    .authentification-form {

        > * {
            margin: 5px 0;
        }

        .forgot-password-link {
            margin-top: -3px;
        }

        .submit-button {
            margin-top: 10px;
            margin-bottom: 14px;
        }
    }
}

@include mobile-and-tablet {
    .authentification-form {
        .register-link {
            justify-content: right;
        }
    }
}