@import 'src/themes/variables.module';
@import 'src/themes/mixins';

.fullwidth {
    max-width: unset;
    width: 100%;
}

.fullwidth_margin {
    width: calc(100% - 32px);
    margin-left: auto;
    margin-right: auto;
}

.normal {
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
}