@import 'src/themes/mixins';

.header-mobile-navigation {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 50px 24px 28px 24px;

    > ul {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin: -10px 0;
        padding-left: 1em;
        > li {
            margin: 10px 0;
            display: block;
        }
    }

    .language-selector {
        display: none;
        margin-left: -10px;
    }
}


@include mobile-and-tablet {
    .header-mobile-navigation {
        .language-selector {
            display: unset;
            margin-bottom: 60px;
        }
    }
}