@import 'src/themes/variables.module';
@import "src/themes/mixins";

.content {
  padding: map-get($spacings, 'smallLarge');
}

.wrapper {
  p {
    @include body;
  }

  h1 {
    @include heading1;
  }

  h2 {
    @include heading2-bold;
  }

  h3 {
    @include heading3-bold;
  }

  h4 {
    @include heading4-bold;
  }

  h5 {
    @include heading5;
  }

  h6 {
    @include heading6;
  }
}

.inverted {
  color: map-get($colors, 'greys', 'light');

  .wrapper {
    p, h1, h2, h3, h4, h5, h6 {
      color: map-get($colors, 'greys', 'light');
    }
  }
}

.withBackground {
  background-color: rgba(map-get($colors, 'greys', 'lightDark'), .5);

  &.inverted {
    background-color: rgba(map-get($colors, 'greys', 'darkLight'), .5);

    .wrapper {
      p, h1, h2, h3, h4, h5, h6 {
        background-color: rgba(map-get($colors, 'greys', 'darkLight'), .5);
      }
    }
  }
}