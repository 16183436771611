@import "src/themes/variables.module";

.audio-player {
  max-width: 740px;
  padding: 24px;
  margin: auto;
}

.audio-player-information {
  display: flex;
  justify-content: center;
  padding: 0 2rem 0.4rem;
  gap: 1rem;
}

.artwork {
  border-radius: 3px;
  width: 100%;
  max-width: 240px;
  max-height: none!important;
  height: auto!important;
}

.track-info {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.artwork, .track-info {
  flex-grow: 0;
  flex-basis: 50%;
}

.title {
  max-width: 740px;
  font-weight: 700;
  margin-bottom: 8px;
}

.description {
  white-space: pre-wrap;
  font-weight: 300;
  margin-bottom: 8px;
}

.controls {
  display: flex;
}

.progress-timer {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  width:  100%;
}

@media screen AND (max-width: 540px) {
  .audio-player {
    padding: 24px 0;
  }

  .audio-player-information {
    padding: 0;
    display: block;

    img {
      display: block;
      margin: 0 auto;
      padding-bottom: 10px;
    }
  }

  .progress-timer-symbol {
    display: none;
  }
}