.small {
  height: 250px;
}

.medium {
  height: 500px;
}

.large {
  height: 750px;
}

.full-height {
  height: 100vh;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}