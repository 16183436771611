@import "src/themes/variables.module";

.footer-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;

    .footer-image {
        position: relative;
        width: 100%;
        top: 5vw;
    }

    .footer,
    .reduced-footer {
        color: map-get($colors, greys, light);
        width: 100%;
        border: 0 none;
        border-radius: 0;
        box-shadow: none;
        box-sizing: border-box;
        padding: map-get($spacings, "smallLarge") 0;
        padding: 0 10px;
        max-width: 1420px;

        div:first-child {
            justify-content: space-between;
        }

        div {
            display: flex;
        }
    }

    .footer {
        color: white;
    }

    .footer {
        min-height: 174px;
        min-width: 100%;
        display: flex;
        justify-content: center;
    }

    .reduced-footer {
        min-height: 61px;
        display: flex;
        align-items: center;
    }

    .logo-paragraph {
        color: map-get($colors, "greys", "middleLight");
    }

    .footer-full-body {
        margin: auto;
        max-width: 1440px;
        display: flex;
        flex-direction: column;

        > div {
            flex-wrap: wrap;
            display: flex;
            margin: 0;
            > div {
                flex-wrap: wrap;
                margin: 10px;
                display: flex;
                flex-direction: column;
                > div {
                    margin: 10px;
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
}
