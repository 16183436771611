@import 'src/themes/variables.module';

.footer-additional-links.footer-additional-links.footer-additional-links {
    display: flex;
    flex-direction: row !important;
    margin: unset;
    width: 100%;

    .footer-social-links {
        display: flex;
        flex-direction: row !important;

        > div {
            background-color: white;
            min-width: 20px;
            min-height: 20px;

            margin: 0 10px;
        }
    }

    > div:not(.footer-social-links) {
        align-items: center;
        display: flex;
        flex-direction: row !important;
        flex-wrap: wrap;
        justify-content: space-between;

        > * {
            display: inline-block;

            word-wrap: break-word;

            * {
                display: inline-block !important;
                word-wrap: break-word;
            }
        }

        > a,
        span,
        .copyright {
            color: map-get($colors, greys, middleLight);
        }
    }
}
