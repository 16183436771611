@import 'src/themes/mixins';
@import 'src/themes/variables.module';

.header-footer-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    &.inverted {
        > header * {
            //color: #{map-get($colors, 'greys', 'light')};
            fill: #{map-get($colors, 'greys', 'light')};
        }

        > div {
            color: #{map-get($colors, 'greys', 'light')};
        }
    }

    main {
        @include desktop-and-smaller {
            padding-top: unset;
        }
        @include mobile-only {
            padding-top: unset;
        }
        flex: 1;
        width: calc(100% - 48px);
        max-width: 1420px;
        margin-left: auto;
        margin-right: auto;
        
        @include bp-desktopSmall {
            width: calc(100% - 32px);
        }
    }
}
