@import 'src/themes/mixins';

.quote-section {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;

  .quote-wrapper {
    display: flex;
    position: relative;

    .quote::before {
      position: absolute;
      bottom: -52px;
      left: -55px;
      content: open-quote;
      quotes: "”" "“";
      font-size: 85px;
      font-weight: 900;
      color: map-get($colors, 'greys', 'dark');
    }

    .quote::after {
      position: absolute;
      top: -10px;
      right: -60px;
      content: close-quote;
      quotes: "”" "“";
      font-size: 85px;
      font-weight: 900;
      color: map-get($colors, 'greys', 'dark');
    }

    .quote.inverted::before {
      color: map-get($colors, 'greys', 'light');
    }

    .quote.inverted::after {
      color: map-get($colors, 'greys', 'light');
    }
  }
}

.headline {
  padding-bottom: 15px;
}