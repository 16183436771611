@import "src/themes/mixins";
@import "src/themes/variables.module";

.default-header-mobile-my-account-navigation {
  width: 260px;

  > div {
    width: 260px;
  }

  position: fixed;
  z-index: 99999999;
  display: inline-block;

  .logout-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .logout-button {
      &:hover {
        > svg,
        > svg > path {
          fill: map-get($colors, "primary", "middleDark");
          color: map-get($colors, "primary", "middleDark");
        }
      }
    }
  }
  right: calc(50% - 700px)  !important;
  top: 54px !important;

  @include desktop-small-only {
    right: 30px !important;
  }
  @include mobile-and-tablet {
    right: 35px !important;
    top: 45px !important;
  }
}
