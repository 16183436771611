@import "src/themes/variables.module";
@import "src/themes/mixins";

.product-summary {
  flex: 1 1 0;
}

.media {
  width: 100%;
}

.bordered {
  padding: 20px;
  border: 1px solid map-get($colors, "greys", "dark");
  border-radius: 2px;
}

.topline {
  padding-bottom: 8px;
}

.subline {
  padding-top: 12px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.inverted {
  color: map-get($colors, "greys", "light");

  &.bordered {
    border-color: map-get($colors, "greys", "light");
  }
}