@import "src/themes/variables.module";

.back {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // fix for flickering in Safari
  -webkit-transform: translateZ(0);
  -webkit-transform: rotateY(.5turn);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: rotateY(-180deg);
  height: 100%;
  display: flex;
  flex-direction: column;
  border-top: 1px solid map-get($colors, 'greys', 'dark');
  border-left: 1px solid map-get($colors, 'greys', 'dark');
  border-right: 1px solid map-get($colors, 'greys', 'dark');
  width: 100%;
  
  &.inverted {
    border-top: 1px solid map-get($colors, 'greys', 'light');
    border-left: 1px solid map-get($colors, 'greys', 'light');
    border-right: 1px solid map-get($colors, 'greys', 'light');
    background-color: map-get($colors, 'greys', 'dark');
  }
}

.content {
  padding: 12px 16px 0;
  flex: 1;
  display: flex;
  flex-flow: column;
  gap: 12px;
}

.overlay {
  flex: 1;
}

.footer {
  padding-top: 0;
}

.teaser-border {
  height: 1.5px;
  background-color: map-get($colors, 'greys', 'dark');

  &.inverted {
    background-color: map-get($colors, 'greys', 'light');
  }
}