@import "src/themes/variables.module";
@import "src/themes/mixins";

.table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #{map-get($colors, "greys", "middleLight")};

  td,
  th {
    border-top: 1px solid #{map-get($colors, "greys", "middleLight")};
    border-bottom: 1px solid #{map-get($colors, "greys", "middleLight")};
  }

  th {
    background-color: map-get($colors, "greys", "lightDark");
    padding: 10px 0;
  }

  td {
    text-align: center;
    vertical-align: middle;
    height: 65px;

    @media (max-width: 640px) {
      height: unset;
    }
  }
}

.inverted {
  color: map-get($colors, "greys", "light");

  th {
    color: map-get($colors, "greys", "dark")
  }
}

.fullwidth {
  max-width: unset;
  width: 100%;
}

.fullwidth_margin {
  width: calc(100% - 32px);
  margin-left: auto;
  margin-right: auto;
}

.normal {
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
}