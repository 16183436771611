@import "src/themes/variables.module";
@import "src/themes/mixins";

.default-header-container {
  width: 100%;
  position: relative;
  height: calc(91px + calc(45px * 2));
  z-index: 999;

  > div:nth-of-type(2) {

    @include desktop-only {
      max-width: 1420px;
      margin: auto;
    }
    position: relative;
    height: calc(91px + calc(45px * 2));

    > div:nth-of-type(2) {
      height: 100% !important;
      z-index: #{map-get($zIndices, 'pop-up-overlays')};
    }

    @media (max-width: #{map-get($breakpoints, desktop)}) {
      padding: 0 #{map-get($spacings, "medium")};
    }
  }

  nav {
    > * {
      @include mobile-and-tablet {
        display: none !important;
      }
    }
  }
}

:global(.typo-page) .default-header-container {
  height: auto;
}

.header-profile-icon {
  -webkit-clip-path: polygon(0.8660008083rem 0, calc(100% - 0.8660008083rem) 0, 100% 50%, calc(100% - 0.8660008083rem) 100%, 0.8660008083rem 100%, 0 50%);
  clip-path: polygon(0.8660008083rem 0, calc(100% - 0.8660008083rem) 0, 100% 50%, calc(100% - 0.8660008083rem) 100%, 0.8660008083rem 100%, 0 50%);
  width: 55px;
  height: 100%;
}

.header-profile-mobile-icon {
  position: absolute;
  top: 1px;
  left: 1px;
  -webkit-clip-path: polygon(0.8660008083rem 0, calc(100% - 0.8660008083rem) 0, 100% 50%, calc(100% - 0.8660008083rem) 100%, 0.8660008083rem 100%, 0 50%);
  clip-path: polygon(0.8660008083rem 0, calc(100% - 0.8660008083rem) 0, 100% 50%, calc(100% - 0.8660008083rem) 100%, 0.8660008083rem 100%, 0 50%);
  width: calc(100% - 2px);
  height: calc(100% - 2px);
}