@use 'sass:math';

@import 'src/themes/variables.module';
@import 'src/themes/mixins';

.header-mobile-container {
  position: relative;

  > .header-mobile-nav {
    position: relative;
    min-width: 80px;
    
    @include mobile-and-tablet{
      right: 0;
    }

    > .header-mobile-nav-container {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: map-get($colors, 'greys', 'dark');

      clip-path: polygon(
                      0 0,
                      100% 0,
                      100% 75%,
                      75% 100%,
                      0 100%,
                      0% 100%
      );
    }

    .header-mobile-nav-item.header-mobile-nav-item {
      a {
        text-decoration: none;
      }

      display: block;

      p,
      span {
        color: map-get($colors, 'greys', 'light');
      }
    }
  }

  > .cancelButton {
    position: absolute;
    top: -26px;
    right: -12.5px;
      @include desktop-and-desktop-small{
        min-height: 40px;
        top: -23.5px;
        right: -16px;
        transform: scale(0.8);
      }
      :hover svg {
        > path{
            fill:#{map-get($colors, "primary", "middleDark")};
        }
    }
  }
}
